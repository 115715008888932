import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { TenderOutcomeData } from '.';
import { ApiTenderOutcomeRequest, ApiTenderOutcomeResponse } from './types';
import { GET_TENDER_OUTCOME } from './query';

export function useTenderOutcome(id?: string): {
  data: TenderOutcomeData | null;
  loading: boolean;
} {
  const { data, loading } = useQuery<ApiTenderOutcomeResponse, ApiTenderOutcomeRequest>(GET_TENDER_OUTCOME, {
    variables: {
      procurementId: id ?? ''
    },
    skip: !id,
    fetchPolicy: 'cache-first'
  });

  return useMemo(
    () => ({
      data: data?.getTender ? new TenderOutcomeData(data.getTender) : null,
      loading
    }),
    [loading, data?.getTender]
  );
}
