import { BidQuestionType } from 'src/models/bids/BidFull/types';
import { safeCallSegment } from '../helpers';

/** Compliance, Status, Response, Requirement */
type TaskTrackProps = 'task stage' | 'task status' | 'task answer type' | 'task requirement';
type TaskTrackEventSource = 'Bidding Tool page' | 'Task Details Page' | 'Bid Document Draft Page';
type TaskTrackPickerMode = 'single' | 'bulk';
type TaskTrackData = {
  mode: TaskTrackPickerMode;
  taskProp: TaskTrackProps;
  groupId?: string;
  bidId?: string;
  taskType?: BidQuestionType;
  value?: string;
  eventSource?: TaskTrackEventSource;
};
export type TaskTrackPickerData = Omit<TaskTrackData, 'value' | 'taskProp'>;
export type TaskTrackCreateData = {
  groupId: string;
  bidId?: string;
  taskId: string;
  eventSource: TaskTrackEventSource;
  mode: 'empty' | 'highlight';
};

/** BT: Create task */
export function trackTaskCreate(props: TaskTrackCreateData): void {
  safeCallSegment(() => {
    analytics.track('BT create task', props);
  });
}

/** BT: Update task: Task stage, Task status, task answer type, task requirement */
export function trackTaskUpdate(props: TaskTrackData): void {
  const { taskProp, ...restProps } = props;
  safeCallSegment(() => {
    analytics.track(`BT ${taskProp}`, restProps);
  });
}

/** BT: Delete task */
export function trackBTDeleteTask(props: TaskTrackPickerData): void {
  safeCallSegment(() => {
    analytics.track('BT delete task', props);
  });
}

// BT upload file
export function trackBTUploadFile(taskId: string, groupId?: string, bidId?: string, taskType?: BidQuestionType): void {
  safeCallSegment(() => {
    analytics.track('BT upload file', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT saves free text
export function trackBTSavesFreeText(
  taskId: string,
  groupId?: string,
  bidId?: string,
  taskType?: BidQuestionType
): void {
  safeCallSegment(() => {
    analytics.track('BT saves free text', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT: open detailed task view
export function trackBTOpenDetailedTaskView(args: {
  taskId: string;
  eventSource: string;
  groupId?: string;
  bidId?: string;
  taskType?: BidQuestionType;
}): void {
  const { taskId, eventSource, groupId, bidId, taskType } = args;
  safeCallSegment(() => {
    analytics.track('BT open detailed task view', {
      bidId,
      groupId,
      taskId,
      taskType,
      eventSource
    });
  });
}

// BT: generate answer
export function trackBTGenerateAnswer(
  taskId: string,
  groupId?: string,
  bidId?: string,
  taskType?: BidQuestionType
): void {
  safeCallSegment(() => {
    analytics.track('BT generate answer', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT: use generated answer
export function trackBTUseGeneratedAnswer(
  taskId: string,
  groupId?: string,
  bidId?: string,
  taskType?: BidQuestionType
): void {
  safeCallSegment(() => {
    analytics.track('BT use generated answer', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT: show more on generated answer
export function trackBTShowMoreOnGeneratedAnswer(
  taskId: string,
  groupId?: string,
  bidId?: string,
  taskType?: BidQuestionType
): void {
  safeCallSegment(() => {
    analytics.track('BT show more on generated answer', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT: show more
export function trackBTShowMore(taskId: string, groupId?: string, bidId?: string, taskType?: BidQuestionType): void {
  safeCallSegment(() => {
    analytics.track('BT show more', {
      bidId,
      groupId,
      taskId,
      taskType
    });
  });
}

// BT: Filtering
export function trackFilterTasks({
  bidId,
  taskProp,
  values
}: {
  taskProp: TaskTrackProps;
  bidId?: string;
  values?: string[];
}): void {
  safeCallSegment(() => {
    analytics.track(`BT Filtering`, {
      bidId,
      taskProp,
      values: values?.toString()
    });
  });
}
