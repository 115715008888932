import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import classNames from 'classnames';
import styles from './index.module.scss';

export const BidsFilter: FC = () => {
  const { t } = useTranslation();
  const [searchVar, updateSearch] = useBidsSearchArgs();

  const updateBidsSearchVars = useCallback(
    (val: boolean) => {
      if (val !== searchVar.rejected) updateSearch({ ...searchVar, rejected: val });
    },
    [searchVar, updateSearch]
  );

  const { rejected } = searchVar;

  return (
    <>
      <div className={styles.title}>{t('BidSpaces.bidStatus')}</div>
      <div className={styles.buttonsContainer}>
        <button
          className={classNames(styles.button, { [styles.isActive]: !rejected })}
          onClick={() => updateBidsSearchVars(false)}
        >
          {t('BidSpaces.active')}
        </button>
        <button
          className={classNames(styles.button, { [styles.isActive]: rejected })}
          onClick={() => updateBidsSearchVars(true)}
        >
          {t('BidSpaces.rejected')}
        </button>
      </div>
    </>
  );
};

export default BidsFilter;
