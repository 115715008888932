import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toStatusName } from 'src/models/workspace/WorkspaceStatus/helpers';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import styles from './index.module.scss';

interface Props {
  status: IWorkspaceStatus;
}

export const BidCategoryBadge: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const name = toStatusName(status.name, t);

  return (
    <span className={classNames(styles.badge, styles[`theme${status.category}`])}>
      <span>{name}</span>
    </span>
  );
};
