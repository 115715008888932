import { MpCodeTypes } from 'src/models/matchingProfiles/types';
import { safeCallSegment } from '../helpers';
import { TendersTypes, TendersVars } from 'src/models/procurements/Tenders/types';

export function trackRejectProcurements(
  procurementIds: readonly string[],
  eventSource: string,
  relevanceScores?: (number | undefined)[]
): void {
  safeCallSegment(() => {
    analytics.track('Reject procurements', {
      procurementIds,
      eventSource,
      relevanceScores
    });
  });
}

export function trackUnrejectProcurements(procurementIds: readonly string[], eventSource: string): void {
  safeCallSegment(() => {
    analytics.track('Unreject procurements', {
      procurementIds,
      eventSource
    });
  });
}

export function trackMoveProcurementsToWorkspace(
  procurementIds: readonly string[],
  ws: string,
  matchingProfileId: string | null,
  eventSource: string
): void {
  safeCallSegment(() => {
    analytics.track('Move procurements to WS', {
      procurementIds,
      ws,
      matchingProfileId,
      eventSource
    });
  });
}

export function trackRemoveProcurementsFromWorkspace(
  procurementIds: readonly string[],
  ws: string,
  eventSource: string
): void {
  safeCallSegment(() => {
    analytics.track('Remove procurements from WS', {
      procurementIds,
      ws,
      eventSource
    });
  });
}

export function trackProcurementPreview(procurement: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('Procurement preview', {
      procurement,
      procurementId: procurement.id // @deprecated. backward compatibility
    });
  });
}

export function trackProcurementCategory(procurement: { id: string; name: string }, category: { name: string }): void {
  safeCallSegment(() => {
    analytics.track('Details page category', {
      procurement,
      category
    });
  });
}

export function trackPrintSummary(procurement: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('Print summary', {
      procurement
    });
  });
}

export function trackStarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  safeCallSegment(() => {
    analytics.track('Star procurement', {
      procurement,
      eventSource
    });
  });
}

export function trackUnstarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  safeCallSegment(() => {
    analytics.track('Unstar procurement', {
      procurement,
      eventSource
    });
  });
}

export function trackOpenProcurementFromEmail(procurement: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Open procurement from email', {
      procurement
    });
  });
}

export function trackViewDocumentPreview(eventSource: string, procurementIds?: string[]): void {
  safeCallSegment(() => {
    analytics.track('View Document Preview', {
      eventSource,
      procurementIds
    });
  });
}

export function trackTendersFilterSearch(eventSource: TendersTypes): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Search Field`);
  });
}

export function trackTendersFilterBuyers(eventSource: TendersTypes): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Buyers`);
  });
}

export function trackTendersFilterCodes(eventSource: TendersTypes, type: MpCodeTypes, optionId: string): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering ${type}`, { optionId });
  });
}

export function trackTendersFilterAssigned(eventSource: TendersTypes): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Assigned`);
  });
}

export function trackTendersFilterDate(
  eventSource: TendersTypes,
  type: keyof Pick<TendersVars, 'published' | 'deadline'>
): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering ${type}`);
  });
}

export function trackTendersFilterStatuses(eventSource: TendersTypes, status: string): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Status`, {
      status: {
        [status.toLowerCase()]: true
      }
    });
  });
}

export function trackTendersFilterContractEndTimeSpan(
  eventSource: TendersTypes,
  data: { start?: number; end?: number }
): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Contract End: Time Span`, data);
  });
}
