import React, { FC, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoin } from '@fortawesome/pro-light-svg-icons';
import { formatDate } from 'src/helpers/dates';
import { capitalizeFirstLetter } from 'src/helpers/capitalizeFirstLetter';
import { Skeleton } from 'src/common';
import { ApiTenderTransactionsInput } from 'src/models/procurements/Billing/types';
import { getIndexFromContractPeriod, toCurrencyValue } from 'src/models/procurements/Billing/helpers';
import styles from './index.module.scss';
import { SupplierTransactions } from '../types';
import { useTranslation } from 'react-i18next';

interface Props {
  data?: SupplierTransactions;
  vars?: ApiTenderTransactionsInput;
  currency?: string;
  loading: boolean;
}

const MonthlyPaymentTable: FC<Props> = ({ currency, data, vars, loading }) => {
  const { t } = useTranslation();
  const { dates = [], transactions = [], supplier } = data ?? {};
  const transactionsByDates = useMemo(() => {
    return dates.map(period => {
      const transaction = transactions.find(tr => tr.period === period);

      return {
        period,
        transaction,
        contractPeriodType: getIndexFromContractPeriod(
          period,
          vars?.contractStart,
          vars?.contractEnd,
          vars?.extensionEndDates
        )
          ?.map(j => (j ? t('ExpiringContracts.extension', { count: j }) : t('ExpiringContracts.originContractPeriod')))
          .join('/ ')
      };
    });
  }, [dates, t, transactions, vars?.contractEnd, vars?.contractStart, vars?.extensionEndDates]);

  if (loading) {
    return <Skeleton active loading />;
  }

  if (!data) return null;

  return (
    <div className={styles.table}>
      {supplier && (
        <div className={styles.title}>
          {supplier.organisationName}
          <div className={styles.subtitle}>({supplier.organisationNumber})</div>
        </div>
      )}
      <ul>
        {transactionsByDates.map(d => (
          <li key={d.period}>
            <div className={styles.name}>
              <div className={styles.text}>
                {capitalizeFirstLetter(formatDate(d.period, 'month', { showMonthName: true, showFullName: true }))}
              </div>
              <div className={styles.secondaryText}>{d.contractPeriodType}</div>
            </div>
            <div className={styles.value}>
              <FontAwesomeIcon icon={faCoin} className={styles.icon} />
              {toCurrencyValue(d.transaction?.amount, currency)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MonthlyPaymentTable;
