import { SupplierSearchTerm } from 'src/models/matchingProfiles/types';
import { SupplierOrg, SupplierString, TendersFilterStatus, TendersVars } from './types';
import { SearchOnlyStatus, SupplierSearchType } from '@tendium/prom-types';

export function isSearchApplied<T extends TendersVars>(filter: T, initFilter: T): boolean {
  const cpvCodesApplied = !!filter.cpvCodes && !!filter.cpvCodes.length;
  const nutCodesApplied = !!filter.nutsCodes && !!filter.nutsCodes.length;
  const deadlineApplied = !!filter.deadline;
  const publishedApplied = !!filter.published;
  const assignedToApplied = !!filter.assignedTo && !!filter.assignedTo.length;
  const statusApplied = filter.onlyStatus !== initFilter.onlyStatus;
  return (
    !!filter.search ||
    cpvCodesApplied ||
    nutCodesApplied ||
    deadlineApplied ||
    publishedApplied ||
    !!filter.name ||
    assignedToApplied ||
    statusApplied ||
    !!filter.awarded
  );
}

export function toSupplierStrings(terms?: SupplierSearchTerm[]): SupplierString[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.SearchStrings)
    .map(({ value, status }) => ({ orgName: value, status }));
}
export function toSupplierIds(terms?: SupplierSearchTerm[]): SupplierOrg[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.OrgIds)
    .map(({ value, status }) => ({ orgId: value, status }));
}

export function getNextOffset(length: number, total: number, pageSize: number): { offset: number } {
  return {
    offset: Math.min(Math.floor(length / pageSize) * pageSize, total)
  };
}

export function getQueryParamsFromTenderStatus(status?: TendersFilterStatus): {
  onlyStatus?: SearchOnlyStatus;
  awarded?: boolean;
} {
  const defaultParams = {
    onlyStatus: undefined,
    awarded: undefined
  };
  if (!status) return defaultParams;
  switch (status) {
    case TendersFilterStatus.all:
      return {
        onlyStatus: undefined,
        awarded: undefined
      };
    case TendersFilterStatus.awarded:
      return {
        onlyStatus: undefined,
        awarded: true
      };
    case TendersFilterStatus.expired:
      return {
        onlyStatus: SearchOnlyStatus.Expired,
        awarded: undefined
      };
    case TendersFilterStatus.recent:
      return {
        onlyStatus: SearchOnlyStatus.Recent,
        awarded: undefined
      };
    default:
      return defaultParams;
  }
}
