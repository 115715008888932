import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { Card as BaseCard } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';

export interface ICardProps {
  bordered?: boolean;
  className?: string;
  extra?: React.ReactNode;
  loading?: boolean;
  title?: ReactNode;
  style?: CSSProperties;
  custom?: boolean;
  id?: string;
  size?: 'small' | 'default';
}

const Card: FC<ICardProps & PropsWithChildren> = props => {
  const { bordered, className, children, extra, loading, style, title, custom, id, size } = props;
  return (
    <BaseCard
      bordered={custom ? false : bordered}
      title={title}
      loading={loading}
      className={classNames(className, custom ? styles.customCard : '')}
      style={style}
      extra={extra}
      id={id}
      size={size}
    >
      {children}
    </BaseCard>
  );
};

export default Card;
