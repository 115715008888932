import { TFunction } from 'i18next';
import { IWorkspaceStatus, WorkspaceStatus } from './types';
import { LexoRank } from 'lexorank';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';

export function isOutcomeWorkspaceStatus(statusName?: string): boolean {
  if (!statusName) return false;
  return [WorkspaceStatus.Awarded, WorkspaceStatus.Lost].includes(statusName as WorkspaceStatus);
}

export function isNobidWorkspaceStatus(statusName?: string): boolean {
  if (!statusName) return false;
  return [WorkspaceStatus.Rejected].includes(statusName as WorkspaceStatus);
}

export function isInactiveWorkspaceStatus(statusName?: string): boolean {
  if (!statusName) return false;
  return [WorkspaceStatus.Review, WorkspaceStatus.Submit, WorkspaceStatus.Evaluation].includes(
    statusName as WorkspaceStatus
  );
}

export function geneRankToLeft(list: IWorkspaceStatus[], id: string): string | null {
  const index = list.findIndex(item => item.id === id);
  if (index === -1) {
    return null;
  }
  const rank = LexoRank.parse(list[index].rank);
  if (index === 0) {
    return rank.genPrev().toString();
  }
  const prev = LexoRank.parse(list[index - 1].rank);
  return rank.between(prev).toString();
}

export function geneRankToRight(list: IWorkspaceStatus[], id: string): string | null {
  const index = list.findIndex(item => item.id === id);
  if (index === -1) {
    return null;
  }
  const rank = LexoRank.parse(list[index].rank);
  if (index === list.length - 1) {
    return rank.genNext().toString();
  }
  const next = LexoRank.parse(list[index + 1].rank);
  return rank.between(next).toString();
}

export function isStatusNameExisting(statuses: IWorkspaceStatus[], name: string): boolean {
  return !!statuses.find(status => status.name === name);
}

export function toNewStatusName(statuses: IWorkspaceStatus[], baseName: string): string {
  const newStages = statuses
    .filter(status => new RegExp('^' + baseName + '\\s?[1-9]?[0-9]*$').test(status.name))
    .sort((a, b) => {
      const aNum = parseInt(a.name.replace(baseName, '')) || 0;
      const bNum = parseInt(b.name.replace(baseName, '')) || 0;
      return aNum > bNum ? 1 : -1;
    });
  const count = newStages.length;
  if (!count) {
    return baseName;
  }
  const num = parseInt(newStages[count - 1].name.replace(baseName, '')) || 0;
  return `${baseName} ${num + 1}`;
}

export function toStatusName(originName: string | undefined, t: TFunction): string {
  if (!originName) {
    return t('Common.noName');
  }
  // only tranlate names in WorkspaceStatus (previously in database before migration - PROM-2767)
  if (Object.values(WorkspaceStatus).includes(originName as WorkspaceStatus)) {
    return t(`BidSpaces.WorkspaceStatus.${originName}`);
  }
  return originName;
}

export function toSortedStatuses(statuses: IWorkspaceStatus[]): IWorkspaceStatus[] {
  let temp: IWorkspaceStatus[] = [];
  for (const cate in WorkspaceStatusCategory) {
    const list = statuses.filter(status => status.category === cate).sort((a, b) => a.rank.localeCompare(b.rank));
    temp = [...temp, ...list];
  }
  return temp;
}
