import React, { FC, useCallback, useMemo } from 'react';
import { EmptyFilter, List, Paging, PagingProps, Spinner } from 'src/common';
import { useDeadlineComingUpBids } from 'src/models/bids/Bids/hooks';
import { DashboardCard } from '../DashboardCard';
import { useBidsSearchArgs } from 'src/reactiveVars';
import styles from './index.module.scss';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import BidListItem from '../BidListItem';
import { DASHBOARD_TABLE_PAGE_SIZE } from 'src/models/bids/Bids/types';
import { calcPage } from 'src/helpers/calcPage';

export const DeadlineComingUpBidsCard: FC = () => {
  const { t } = useTranslation();
  const { fetchMore, data, loading, fetchingMore } = useDeadlineComingUpBids({ amount: DASHBOARD_TABLE_PAGE_SIZE });
  const bids = useMemo(() => (data ? data.bids : []), [data]);

  const [searchVars, updateSearchArgs] = useBidsSearchArgs();
  const offset = searchVars.offset ?? 0;
  const limit = DASHBOARD_TABLE_PAGE_SIZE;
  const currentBids = useMemo(() => bids?.slice(offset, offset + limit), [limit, offset, bids]);
  const currentPage = useMemo(() => calcPage(offset, limit), [offset, limit]);

  const onChange: PagingProps['onChange'] = useCallback(
    (page: number) => {
      const currentOffset = (page - 1) * limit;

      if (bids?.slice(currentOffset, currentOffset + limit).some(bid => !bid)) {
        fetchMore?.({ variables: { offset: currentOffset } }).then(() => {
          updateSearchArgs({ ...searchVars, offset: currentOffset });
        });
      } else {
        updateSearchArgs({ ...searchVars, offset: currentOffset });
      }
    },
    [bids, fetchMore, limit, searchVars, updateSearchArgs]
  );

  return (
    <DashboardCard>
      <div>
        <div className={styles.header}>{t('BidSpaces.Dashboard.DeadlinesComingUp.title')}</div>
        {(bids && bids.length > 0) || loading ? (
          <List items={currentBids} loading={loading}>
            {bid => bid && <BidListItem bid={bid} />}
          </List>
        ) : (
          <EmptyFilter
            icon={faSparkles}
            title={t('BidSpaces.Dashboard.EmptyFilter.title')}
            desc={t('BidSpaces.Dashboard.EmptyFilter.desc')}
          />
        )}
      </div>
      {bids && (
        <Paging
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={onChange}
          pageSize={DASHBOARD_TABLE_PAGE_SIZE}
          total={data?.total}
          className={styles.paging}
          itemRender={
            fetchingMore
              ? (page, type, originalElement) =>
                  page === currentPage && type === 'page' ? <Spinner /> : originalElement
              : undefined
          }
        />
      )}
    </DashboardCard>
  );
};

export default DeadlineComingUpBidsCard;
