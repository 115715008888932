import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { signIn, signOut, signInWithRedirect, type AuthError, getCurrentUser } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, notification } from 'src/common';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import styles from '../index.module.scss';
import { GoogleIcon } from 'src/common/icons';
import { SsoProvider } from 'src/models/users/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import AuthWarning from '../AuthWarning';
import { useApp, useAwsError, useMsTeamsUrl, generateMsTeamsSsoUrl } from 'src/models/auth';
import { AmplifyError, AuthQueryParams } from 'src/models/auth/types';
import { Translations } from 'src/lib/I18nService/types';
import { useQueryParams } from 'src/helpers';
import { StorageKey } from 'src/types/enums';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Ownerships, ownership } from 'src/models/ownership';
import 'aws-amplify/auth/enable-oauth-listener';
import * as Sentry from '@sentry/react';

interface FormState {
  email: string;
  password: string;
}

export const SignInPage: FC = () => {
  const { user, error, setAuth } = useApp();
  const { t } = useTranslation();
  const { t: cognito_tr } = useTranslation(Translations.cognitoErrors);
  const from = sessionStorage.getItem(StorageKey.FromLogin);
  const redirectTo = useMemo(() => (!!from ? from : Paths.ROOT), [from]);
  const isFreeLink = useMemo(() => from?.includes(`${Paths.TENDER_ROUTE.slice(0, -1)}?auth=`), [from]);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const qp = useQueryParams();
  const [getMsTeamUrl] = useMsTeamsUrl();
  const [awsError, clearAwsError] = useAwsError();
  const [warningVisible, setWarningVisible] = useState(false);
  const onOpenWarning = useCallback(() => {
    setWarningVisible(true);
  }, []);
  const onCloseWarning = useCallback(async () => {
    setWarningVisible(false);
    if (awsError) {
      clearAwsError();
    }
    if (error?.message === AmplifyError.DisabledUser) {
      await signOut();
    }
  }, [awsError, clearAwsError, error?.message]);

  const initialValues = useMemo((): FormState => {
    return {
      email: '',
      password: ''
    };
  }, []);

  useEffect(() => {
    if (error?.message === AmplifyError.DisabledUser || !!awsError) {
      onOpenWarning();
    }
  }, [awsError, error?.message, onOpenWarning]);

  /** link to amplify@v6 ErrorException enums:
   * https://github.com/aws-amplify/amplify-js/blob/1a1a6ce60a331fe4910bd7fc3ca8ab7953a51c0e/packages/auth/src/providers/cognito/types/errors.ts#L180
   */
  const showError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      const { name } = error as AuthError;
      const passwordHasExpiredMessage = 'Temporary password has expired and must be reset by an administrator.';
      if (name) {
        if (
          typeof error.message === 'string' &&
          (error.message === passwordHasExpiredMessage || error.message.includes(passwordHasExpiredMessage))
        ) {
          form.setFields([
            {
              name: 'email',
              errors: [t('Auth.temporaryPasswordExpired')]
            }
          ]);
        } else if (name === 'UserNotFoundException') {
          /** it changed now to { name: 'NotAuthorizedException', message: 'Incorrect username or password.' } */
          form.setFields([
            {
              name: 'email',
              errors: [t('Auth.userNotExist')]
            }
          ]);
        } else if (name === 'UserNotConfirmedException') {
          navigate(Paths.CONFIRM_SIGN_UP, { replace: true });
        } else {
          form.setFields([
            {
              name: 'email',
              errors: [error.message]
            }
          ]);
        }
      } else {
        notification.error({
          message: t('Auth.notSignedIn'),
          description: t('Common.unknownErrorDesc')
        });
      }
    },
    [form, navigate, t]
  );

  const onSignIn = useCallback(
    async (email: string, password: string) => {
      try {
        const { userId } = await getCurrentUser();
        if (userId) {
          sessionStorage.clear();
          navigate(redirectTo, { replace: true });
        }
      } catch {
        const { nextStep } = await signIn({ username: email, password });
        setAuth({ email: email.toLowerCase() });

        try {
          if (qp[AuthQueryParams.MsRedirectUri]) {
            const msTeamsData = await getMsTeamUrl({
              variables: { state: String(qp[AuthQueryParams.MsState]), password, email }
            });
            if (msTeamsData.data?.getMicrosoftRedirectUrlForMe) {
              window.location.href = msTeamsData.data.getMicrosoftRedirectUrlForMe;
            }
          } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            navigate(Paths.CHANGE_PASSWORD, { replace: true });
          } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
            navigate(Paths.CONFIRM_2FA, { replace: true });
          } else if (nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
            navigate(Paths.SETUP_2FA, { replace: true });
          } else {
            sessionStorage.clear();
            navigate(redirectTo, { replace: true });
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            if (error.message.includes(AmplifyError.IllegalOrigin)) {
              showError({ name: 'UserNotFoundException' });
            } else {
              /** temporary, until all new amplify-exceptions caught and handled in showError fn */
              Sentry.captureException(error);
              notification.error({
                message: t('Auth.notSignedIn'),
                description: t('Common.unknownErrorDesc')
              });
            }
          } else {
            console.error({ error });
          }
        }
      }
    },
    [getMsTeamUrl, navigate, qp, redirectTo, setAuth, showError, t]
  );

  const onFinish = useCallback(
    async (values: FormState) => {
      const { email, password } = values;
      const trimmedPassword = password.trim();
      setIsSubmitting(true);
      try {
        await onSignIn(email, trimmedPassword);
      } catch (error: unknown) {
        if (error instanceof Error) {
          const incorrectPasswordOrNameMessage = 'Incorrect username or password.';
          const incorrectPasswordOrName =
            error.message === incorrectPasswordOrNameMessage || error.message.includes(incorrectPasswordOrNameMessage);

          if (trimmedPassword !== password && incorrectPasswordOrName) {
            await onSignIn(email, password).catch(err => showError(err));
          } else {
            showError(error);
          }
        } else {
          console.error(error);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSignIn, showError]
  );

  const onSsoSignIn = useCallback(
    (ssoGroup: SsoProvider) => {
      if (qp[AuthQueryParams.MsRedirectUri]) {
        window.location.href = generateMsTeamsSsoUrl(ssoGroup, qp[AuthQueryParams.MsState]);
      } else {
        signInWithRedirect({ provider: { custom: ssoGroup } })
          .then(() => {
            navigate(redirectTo, { replace: true });
          })
          .catch(() => {
            notification.error({
              message: t('Common.unknownError'),
              description: t('Common.unknownErrorDesc')
            });
          });
      }
    },
    [navigate, qp, redirectTo, t]
  );

  if (user && !qp[AuthQueryParams.MsRedirectUri]) {
    return <Navigate to={Paths.ROOT} replace />;
  }

  return !user || qp[AuthQueryParams.MsRedirectUri] ? (
    <>
      <Form form={form} initialValues={initialValues} onFinish={onFinish} layout={'vertical'} className={styles.form}>
        <div className={styles.heading}>
          <h3 className={styles.title}>
            <span>{t('Auth.login.title')}</span>
          </h3>
          <span className={styles.desc}>{t('Auth.login.desc')}</span>
        </div>
        <Form.Item
          name="email"
          label={t('Auth.emailAddress')}
          rules={[{ required: true, message: t('Auth.enterEmail') }]}
        >
          <Input placeholder={t('Common.email')} autoComplete={'username'} type={'email'} />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('Common.password')}
          rules={[{ required: true, message: t('Auth.enterPassword') }]}
        >
          <Input.Password
            placeholder={t('Common.password')}
            autoComplete={'current-password'}
            type={'password'}
            iconRender={visible => <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />}
          />
        </Form.Item>
        {ownership.name !== Ownerships.Clira && (
          <Link to={{ pathname: Paths.FORGOT_PASSWORD }} className={classNames(styles.forgotLink, styles.link)}>
            {t('Auth.common.forgetPassword')}
          </Link>
        )}
        <div className={styles.actions}>
          <Button htmlType={'submit'} type={'primary'} loading={isSubmitting}>
            {t('Auth.common.buttons.login')}
          </Button>
          {isFreeLink && ownership.name !== Ownerships.Clira && (
            <Link to={{ pathname: Paths.SIGN_UP }} className={styles.link}>
              {t('Auth.common.signUp')}
            </Link>
          )}
        </div>
      </Form>
      <div className={styles.sso}>
        <span className={styles.ssoTitle}>{t('Common.or')}</span>
        <ul className={styles.ssoActions}>
          {ownership.name !== Ownerships.Clira && (
            <li className={styles.ssoAction}>
              <Button
                type={'text'}
                icon={<GoogleIcon />}
                onClick={() => onSsoSignIn(SsoProvider.google)}
                className={styles.ssoActionButton}
              >
                {t('Auth.login.buttons.google')}
              </Button>
            </li>
          )}
          <li className={styles.ssoAction}>
            <Link
              to={{
                pathname: Paths.SIGN_IN_SSO,
                search: qp[AuthQueryParams.MsState] ? `state=${qp[AuthQueryParams.MsState]}` : ''
              }}
              className={classNames(styles.text, styles.ssoActionButton)}
            >
              <FontAwesomeIcon icon={faUserLock} className={styles.ssoIcon} />
              {t('Auth.login.buttons.saml')}
            </Link>
          </li>
        </ul>
      </div>
      {warningVisible && (
        <AuthWarning
          description={
            error?.message === AmplifyError.DisabledUser
              ? t(`Auth.common.errors.${AmplifyError.DisabledUser}`)
              : !!awsError
              ? cognito_tr(awsError.errorMessage, { option: awsError.option })
              : ''
          }
          onClose={onCloseWarning}
        />
      )}
    </>
  ) : (
    <Navigate to={Paths.ROOT} replace />
  );
};

export default SignInPage;
