import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { Skeleton } from 'src/common';
import { KPI } from 'src/common/KPI';
import { KPIs } from 'src/common/KPIs';
import { useBidspaceStatistics } from 'src/models/bids/Bids/hooks';

export const BidspacesStatistic: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useBidspaceStatistics();

  return loading ? (
    <Skeleton loading active />
  ) : data?.statistics ? (
    <KPIs>
      {data?.statistics.map(({ key, value }) => (
        <KPI
          key={key}
          name={t(`BidSpaces.WorkspaceStatusCategory.${key}`)}
          icon={<FontAwesomeIcon icon={value.icon.defaultIcon ?? faList} style={{ color: value.icon.color }} />}
          count={value.count}
          additional={`${t('Common.Format.formatNumber', { number: value.amount / 1000000 })} MSEK`}
        />
      ))}
    </KPIs>
  ) : null;
};

export default BidspacesStatistic;
