import { ApolloError, LazyQueryResultTuple, NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { formatDate } from 'src/helpers/dates';
import {
  ApiContractPeriodDatesOutput,
  ApiTenderPeriodTransactionsInput,
  ApiTenderTransactionsInput,
  ApiTenderTransactionsOutput,
  DISPLAY_SUPPLIER_COUNT,
  PeriodTransaction,
  TenderContractInfo,
  ApiTenderTransactionData
} from './types';
import {
  GET_TENDER_CONTRACT_INFO,
  GET_SIMPLE_OVERLAPPING_TENDERS,
  GET_TRANSACTIONS_PERIOD,
  GET_TRANSACTIONS_PERIOD_AND_CUMULATIVE
} from './queries';
import { toAwardedSuppliers } from '../helpers';
import { calcContractPeriodInput } from './helpers';
import { ApiTendersData, ApiTendersItem, ApiTendersVars, SupplierOrg } from '../Tenders/types';
import { TendersListData } from '../Tenders';
import { AwardStatus, ContractEndRangeMode, ContractEndSearchType } from '@tendium/prom-types';
import { TenderContractInfoData, TenderTransactionData } from '.';
import { useTenderOutcome } from '../PreviewOutcome/hooks';

export function useTenderTransactionsData(
  tenderId?: string,
  skip?: boolean
): {
  data: ApiTenderTransactionData | null;
  vars?: ApiTenderTransactionsInput;
  error?: ApolloError;
  loading: boolean;
} {
  const { data: outcomeData, loading: outcomeLoading } = useTenderOutcome(tenderId);
  const suppliers = useMemo(() => toAwardedSuppliers(outcomeData?.rows), [outcomeData?.rows]);
  const supplierOrgIds = useMemo(() => {
    const ids = suppliers.map(s => s.organisationNumber);
    return Array.from(new Set(ids));
  }, [suppliers]);
  const { data: contractInfo, loading: contractInfoLoading } = useTenderContractInfo(tenderId, !supplierOrgIds.length);
  const vars = useMemo(
    () =>
      contractInfo
        ? {
            supplierOrgIds,
            buyerOrgIds: contractInfo?.buyerOrgIds ?? [],
            contractStart: contractInfo?.contractStart ? formatDate(contractInfo.contractStart) : undefined,
            contractEnd: contractInfo?.contractEnd ? formatDate(contractInfo.contractEnd) : undefined,
            extensionEndDates: contractInfo?.extensionEndDates
              ? contractInfo.extensionEndDates.map(dt => formatDate(dt))
              : undefined,
            ...calcContractPeriodInput(contractInfo)
          }
        : undefined,
    [contractInfo, supplierOrgIds]
  );

  const { data, loading, error } = useQuery<
    ApiTenderTransactionsOutput<ApiTenderTransactionData>,
    ApiTenderTransactionsInput
  >(GET_TRANSACTIONS_PERIOD_AND_CUMULATIVE, {
    variables: vars ? { displaySupplierCount: DISPLAY_SUPPLIER_COUNT, ...vars } : undefined,
    skip: !!skip || !tenderId || !vars
  });

  return useMemo(
    () => ({
      vars,
      data: data?.getTransactionsPeriod ? new TenderTransactionData(data.getTransactionsPeriod, suppliers) : null,
      error,
      loading: loading || contractInfoLoading || outcomeLoading
    }),
    [contractInfoLoading, data?.getTransactionsPeriod, error, loading, outcomeLoading, suppliers, vars]
  );
}

export function useLazyPeriodTransactions(): LazyQueryResultTuple<
  ApiTenderTransactionsOutput<{ transactions: PeriodTransaction[] }>,
  ApiTenderPeriodTransactionsInput
> {
  return useLazyQuery<
    ApiTenderTransactionsOutput<{ transactions: PeriodTransaction[] }>,
    ApiTenderPeriodTransactionsInput
  >(GET_TRANSACTIONS_PERIOD);
}

export function useTenderContractInfo(
  tenderId?: string,
  skip?: boolean
): {
  data: TenderContractInfo | null;
  loading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<ApiContractPeriodDatesOutput, { id: string }>(GET_TENDER_CONTRACT_INFO, {
    variables: { id: tenderId ?? '' },
    skip: !!skip || !tenderId
  });

  return useMemo(() => {
    return {
      data: data?.getTender ? new TenderContractInfoData(data) : null,
      loading,
      error
    };
  }, [data, loading, error]);
}

export function useOverlappingTenders(
  orgIds: string[],
  buyerOrgId: string,
  contractStart?: string,
  contractEnd?: string
): {
  data: TendersListData<ApiTendersItem> | undefined;
  loading: boolean;
  error?: ApolloError;
} {
  const supplierOrgs: SupplierOrg[] = orgIds.map(orgId => ({ orgId: orgId, status: AwardStatus.Won }));
  const variables: ApiTendersVars = {
    advanced: {
      awarded: true,
      buyerOrgId: buyerOrgId,
      supplierOrgIds: [...supplierOrgs],
      contractEnd: {
        searchMode: ContractEndSearchType.PeriodOverlap,
        rangeMode: ContractEndRangeMode.ABSOLUTE,
        start: contractStart ? new Date(contractStart) : undefined,
        end: contractEnd ? new Date(contractEnd) : undefined
      }
    }
  };

  const queryDataExpContract = useQuery<ApiTendersData, ApiTendersVars>(GET_SIMPLE_OVERLAPPING_TENDERS, {
    variables
    // notifyOnNetworkStatusChange: true
  });

  return useMemo(
    () => ({
      data: !!queryDataExpContract.data
        ? new TendersListData(queryDataExpContract.data.getSimpleTenders, [])
        : undefined,
      loading:
        (queryDataExpContract.loading ||
          queryDataExpContract.data === undefined ||
          queryDataExpContract.networkStatus === NetworkStatus.refetch ||
          queryDataExpContract.networkStatus === NetworkStatus.setVariables) &&
        queryDataExpContract.networkStatus !== NetworkStatus.fetchMore
    }),
    [queryDataExpContract]
  );
}
