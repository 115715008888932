import { createContext } from 'react';
import { ScaleBand, ScaleLinear, ScaleOrdinal } from 'd3-scale';

export type XYChartContext = {
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
  xMax: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
};

export const XYChartContext = createContext<XYChartContext | null>(null);
XYChartContext.displayName = 'XYChartContext';
