import React, { FC } from 'react';
import styles from './index.module.scss';
import AssignedBidsCard from './AssignedBidsCard';
import DeadlineComingUpBidsCard from './DeadlineComingUpBidsCard';

export const BidspacesDashboard: FC = () => {
  return (
    <div className={styles.dashboard}>
      <AssignedBidsCard />
      <DeadlineComingUpBidsCard />
    </div>
  );
};

export default BidspacesDashboard;
