import { createContext } from 'react';
import { MpKeywordAndGroup } from '../matchingProfiles/types';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FilterLogic, KeywordWidth } from '@tendium/prom-types';
import { BaseKeywordDTO } from '@tendium/prom-types/schema';

export type KeywordsContextValue = {
  data: {
    filterLogic: FilterLogic;
    texts: {
      sectionTitle?: string;
      sectionDesc: string;
      sectionIcon?: IconDefinition;
      advancedModalTitle: string;
      searchFieldPlaceholder: string;
      searchFieldButtonLabel: string;
      searchFieldButtonIcon: IconDefinition;
      deleteButtonDesc: string;
      noDataDesc: string;
    };
    keywordWidth?: KeywordWidth;
    keywords?: MpKeywordAndGroup[];
    newKeywords: MpKeywordAndGroup[];
    eventNamePrefix: string;
    mp?: {
      id: string;
      name: string;
    };
    cpvCodes?: string[];
  };
  createKeywords: (keywords: string[], keywordGroups: string[][]) => void;
  updateKeywords: (
    keywordIds: string[],
    keywordGroupIds: { keywordIds: string[]; groupId: string | null }[],
    fieldToUpdate: BaseKeywordDTO
  ) => void;
  deleteKeywords: (keywords: { values: { id: string; value: string }[]; groupId: string | null }[]) => void;
  updateKeywordWidth: (keywordWidth: KeywordWidth) => void;
};
export const KeywordsContext = createContext<KeywordsContextValue | null>(null);
