import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { IBids } from 'src/models/bids/Bids/types';
import { BidCategoryBadge } from 'src/common/BidCategoryBadge';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
import { Tooltip, WorkspaceBadge } from 'src/common';
import { useOpenBidPreview } from 'src/pages/BidsPage/BidsBoard/hooks';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import classNames from 'classnames';
import { useDeadlineDate } from 'src/helpers';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  bid: IBids;
}

export const BidListItem: FC<Props> = props => {
  const { bid } = props;
  const { id, origin, status } = bid;
  const { name, deadline } = origin;
  const { workspace } = useBidWorkspace(id);
  const openPreview = useOpenBidPreview(bid);
  const currentSidebar = useCurrentSidebar();
  const deadlineValue = useDeadlineDate(deadline);

  const isActive = useMemo(
    () => currentSidebar?.mode === SidebarMode.BID_INFO && currentSidebar.id === id,
    [currentSidebar, id]
  );

  return (
    <div className={classNames(styles.item, { [styles.isActive]: isActive })} key={id} onClick={e => openPreview(e)}>
      <div className={styles.titleContainer}>
        <div className={styles.wsBadge}>
          <WorkspaceBadge title={workspace?.name ?? ''} color={workspace?.color ?? ''} mode={'short'} />
        </div>
        <Tooltip title={name}>
          <div className={styles.title}>{name}</div>
        </Tooltip>
      </div>
      <div className={styles.infoContainer}>
        {status && <BidCategoryBadge status={status} />}
        {deadline && (
          <div className={styles.date}>
            <div className={classNames(styles.value, { [styles.isExpired]: deadlineValue.isExpired })}>
              <FontAwesomeIcon
                icon={faBell}
                className={classNames(styles.icon, { [styles.isExpired]: deadlineValue.isExpired })}
              />
              {deadlineValue.value}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BidListItem;
