import { IIconMapper } from 'src/types/icon';
import { NotificationGroupName } from 'src/models/notifications/types';
import {
  faAlarmClock,
  faUserCheck,
  faCommentDots,
  faExclamationCircle,
  faSparkles
} from '@fortawesome/pro-light-svg-icons';

export const NOTIFICATIONS_SETTINGS_ICON_MAPPER: IIconMapper = {
  [NotificationGroupName.DEADLINE]: { defaultIcon: faAlarmClock, color: '#F5222D' },
  [NotificationGroupName.ASSIGNED]: { defaultIcon: faUserCheck, color: '#FA941C' },
  [NotificationGroupName.COMMENTS]: { defaultIcon: faCommentDots, color: '#5357EA' },
  [NotificationGroupName.CHANGES_IN_STATUS]: { defaultIcon: faExclamationCircle, color: '#722ED1' },
  [NotificationGroupName.MP_DAILY_SENDOUT]: { defaultIcon: faSparkles, color: '#5357EA' },
  [NotificationGroupName.WS_SENDOUT]: { defaultIcon: faSparkles, color: '#5357EA' }
};

export const NOTIFICATIONS_SETTINGS_SORT = [
  NotificationGroupName.DEADLINE,
  NotificationGroupName.ASSIGNED,
  NotificationGroupName.COMMENTS,
  NotificationGroupName.CHANGES_IN_STATUS,
  NotificationGroupName.MP_DAILY_SENDOUT,
  NotificationGroupName.WS_SENDOUT
];
