import React, { ReactNode, Ref } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { Button as BaseButton } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';
import Spinner from '../Spinner';

declare const ButtonTypes: ['default', 'primary', 'link', 'text'];
export declare type ButtonType = (typeof ButtonTypes)[number];

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'> {
  htmlType?: ButtonHTMLType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  type?: ButtonType;
  icon?: React.ReactNode;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  isActive?: boolean;
  danger?: boolean;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref: Ref<HTMLElement>) => {
  const { children, type = 'default', loading, className, isActive = false, danger, ...restProps } = props;

  return (
    <BaseButton
      {...restProps}
      icon={loading ? <Spinner /> : restProps.icon}
      ref={ref}
      danger={danger}
      className={classNames(
        styles.button,
        {
          [styles.onlyIcon]: !children,
          [styles.isLoading]: loading,
          [styles.default]: type === 'default',
          [styles.primary]: type === 'primary',
          [styles.text]: type === 'text',
          [styles.link]: type === 'link',
          [styles.isActive]: isActive
        },
        className
      )}
    >
      {children}
    </BaseButton>
  );
});

Button.displayName = 'Button';
export default Button;
