import { IUserName } from 'src/models/users/types';
import { NotificationType } from '@tendium/prom-types/notifications';

export enum NotificationGroupName {
  DEADLINE = 'DEADLINE',
  ASSIGNED = 'ASSIGNED',
  COMMENTS = 'COMMENTS',
  CHANGES_IN_STATUS = 'CHANGES_IN_STATUS',
  MP_DAILY_SENDOUT = 'MP_DAILY_SENDOUT',
  WS_SENDOUT = 'WS_SENDOUT'
}
export interface INotificationFrom {
  email: string;
  createdAt: number;
}

export interface IApiNotificationsData {
  newCounter: number;
  from: INotificationFrom;
  notifications: IApiNotification[];
}

export interface INotificationSearchArgs {
  limit: number;
  from?: INotificationFrom;
}

export interface IApiNotification {
  isRead: boolean;
  isSeen: boolean;
  createdAt: number;
  email: string;
  body: string;
  title: string;
  type: NotificationType;
  isMarkDown: boolean;
  url?: string;
}

export interface INotificationsData {
  readonly unseenCount: number;
  readonly notifications: INotification[];
}

export interface INotification extends Omit<IApiNotification, 'createdBy' | 'type'> {
  createdBy?: IUserName;
  type: NotificationGroupName;
}

export const NOTIFICATIONS_LIMIT = 10;

// NotificationPriority
export enum NotificationPriority {
  Highest,
  High,
  Normal,
  Low,
  Lowest
}

export enum ActiveNotificationsTab {
  Notifications = 'notifications',
  Reminders = 'reminders'
}
