import React, { FC, useCallback, useMemo, useState } from 'react';
import { EmptyFilter, List, Paging, PagingProps } from 'src/common';
import { useAssignedToStatistics } from 'src/models/bids/Bids/hooks';
import { DashboardCard } from '../DashboardCard';
import styles from './index.module.scss';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import AssignedBidsItem from './AssignedBidsItem';
import { DASHBOARD_TABLE_PAGE_SIZE } from 'src/models/bids/Bids/types';

export const AssignedBidsCard: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useAssignedToStatistics();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = data?.statistics.length;
  const [expandedId, setExpandedId] = useState<string | undefined>();
  const statistics = useMemo(
    () =>
      data?.statistics.slice(DASHBOARD_TABLE_PAGE_SIZE * (currentPage - 1), DASHBOARD_TABLE_PAGE_SIZE * currentPage),
    [currentPage, data]
  );
  const onChange: PagingProps['onChange'] = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DashboardCard>
      <div>
        <div className={styles.header}>{t('BidSpaces.Dashboard.AssignedBids.title')}</div>
        {(statistics && statistics?.length > 0) || loading ? (
          <div className={styles.listContainer}>
            <List items={statistics} loading={loading}>
              {stat => <AssignedBidsItem stat={stat} expandedId={expandedId} setExpandedId={setExpandedId} />}
            </List>
          </div>
        ) : (
          <EmptyFilter
            icon={faSparkles}
            title={t('BidSpaces.Dashboard.EmptyFilter.title')}
            desc={t('BidSpaces.Dashboard.EmptyFilter.desc')}
          />
        )}
      </div>
      {statistics && (
        <Paging
          defaultCurrent={1}
          current={currentPage}
          onChange={onChange}
          pageSize={DASHBOARD_TABLE_PAGE_SIZE}
          total={totalPages}
          className={styles.paging}
        />
      )}
    </DashboardCard>
  );
};

export default AssignedBidsCard;
