import React, { JSX, useContext } from 'react';
import { Line, LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { inPeriod } from 'src/helpers/dates';
import { BaseChartProps, CIRCLE_BORDER, CIRCLE_RADIUS, LINE_HEIGHT } from '../types';
import { XYChartContext } from '../XYChart/context';

export function LineGroup<T>(props: BaseChartProps<T>): JSX.Element | null {
  const { data, getLegendKey, getYValue, getXValue, onHover, offHover } = props;

  const context = useContext(XYChartContext);
  if (!context) return null;

  const { xScale, yScale, colorScale, margin } = context;
  const periods = xScale.domain();

  return (
    <Group>
      {data.map((dt, i) => {
        const groupData = data.filter(d => getLegendKey(d) === getLegendKey(dt));

        const isSingleDataPoint = groupData.length === 1;
        let lineToX = 0;
        // if there is only one data point, draw a line from it to last period
        // - Should not surpass today
        if (isSingleDataPoint) {
          const todayInPeriod = inPeriod(undefined, periods);
          const lastPeriod = periods[periods.length - 1];
          lineToX = (xScale(todayInPeriod ? todayInPeriod : lastPeriod) ?? 0) + xScale.bandwidth() / 2;
        }

        const linePath = isSingleDataPoint ? (
          <Line
            from={{
              x: (xScale(getXValue(groupData[0])) ?? 0) + xScale.bandwidth() / 2,
              y: yScale(getYValue(groupData[0]))
            }}
            to={{
              x: lineToX,
              y: yScale(getYValue(groupData[0]))
            }}
            stroke={colorScale(getLegendKey(dt))}
            strokeWidth={LINE_HEIGHT}
          />
        ) : (
          <LinePath<T>
            key={`line-${i}`}
            data={groupData}
            y={d => yScale(getYValue(d))}
            x={d => (xScale(getXValue(d)) ?? 0) + xScale.bandwidth() / 2}
            stroke={colorScale(getLegendKey(dt))}
            strokeWidth={LINE_HEIGHT}
          />
        );
        const circles = groupData.map((d, j) => {
          const cy = yScale(getYValue(d));
          const cx = (xScale(getXValue(d)) ?? 0) + xScale.bandwidth() / 2;

          return (
            <circle
              key={`circle-${i}-${j}`}
              cy={cy}
              cx={cx}
              r={CIRCLE_RADIUS}
              fill="white"
              stroke={colorScale(getLegendKey(dt))}
              strokeWidth={CIRCLE_BORDER}
              onMouseLeave={() => {
                offHover?.();
              }}
              onMouseEnter={() => {
                onHover?.({
                  tooltipData: d,
                  tooltipTop: cy - margin.top + margin.bottom,
                  tooltipLeft: cx + margin.left
                });
              }}
            />
          );
        });
        return (
          <React.Fragment key={`line-circle-${i}`}>
            {linePath}
            {circles}
          </React.Fragment>
        );
      })}
    </Group>
  );
}

export default LineGroup;
