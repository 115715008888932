import React, { FC } from 'react';
import { CumulativeTransaction } from 'src/models/procurements/Billing/types';
import { toCurrencyValue } from 'src/models/procurements/Billing/helpers';
import { toDateRangeStr } from 'src/helpers/dates';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  data: CumulativeTransaction;
  dataIndex: number;
  currency?: string;
}

const HoverTooltipCumulative: FC<Props> = ({ data, dataIndex, currency }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.secondaryTitle}>
        {!!dataIndex
          ? t('ExpiringContracts.extension', { count: dataIndex })
          : t('ExpiringContracts.originContractPeriod')}
      </div>
      <div className={styles.text}>{toDateRangeStr([data.from, data.to])}</div>
      <div className={styles.secondaryTitle}>{t('Tenders.Billing.valueForThisPeriod')}</div>
      <div className={styles.text}>{toCurrencyValue(data.value, currency)}</div>
    </>
  );
};

export default HoverTooltipCumulative;
