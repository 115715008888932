import React, { FC } from 'react';
import styles from './index.module.scss';
import { AssignedToCount } from 'src/models/bids/Bids';
import Accordion from 'src/common/Accordion';
import { useTranslation } from 'react-i18next';
import Assignee from 'src/shared/Assignee';
import { BidsList } from './BidsList';

interface Props {
  stat?: AssignedToCount;
  expandedId?: string;
  setExpandedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const AssignedBidsItem: FC<Props> = ({ stat, expandedId, setExpandedId }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.item} key={stat?.id}>
      <Accordion
        label={
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <Assignee assignedToId={stat?.assignedTo} />
            </div>
            <div className={styles.count}>{t('BidSpaces.Dashboard.assignedToBidCount', { count: stat?.bidCount })}</div>
          </div>
        }
        onToggleComplete={() => setExpandedId(prev => (prev === stat?.id ? undefined : stat?.id))}
        value={expandedId === stat?.id}
        className={styles.accordionThin}
      >
        {stat && stat?.assignedTo && stat.id === expandedId && (
          <BidsList amount={stat?.bidCount} assignedToId={stat?.assignedTo} key={stat.id} />
        )}
      </Accordion>
    </div>
  );
};

export default AssignedBidsItem;
