import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router';
import { UserType, useApp } from 'src/models/auth';
import { Paths } from '../paths';
import { StorageKey } from 'src/types/enums';
import { Ownerships, ownership } from 'src/models/ownership';

/** all authorized redirection (aka user logged in) should be handled in `HomePage` */
export const HomePage: FC = () => {
  const { permission, user } = useApp();
  const fromUrl = sessionStorage.getItem(StorageKey.FromLogin);
  const isPaid = useMemo(() => permission === UserType.Paid, [permission]);
  const isCompany = useMemo(
    () => isPaid && !!user?.company.orgNumber && !!user?.company.companyName,
    [isPaid, user?.company.companyName, user?.company.orgNumber]
  );
  const paidRedirect = useMemo(
    () =>
      isPaid && !isCompany
        ? Paths.START
        : fromUrl ?? ownership.name === Ownerships.Clira
        ? Paths.ALL_TENDERS
        : Paths.MONITORING,
    [fromUrl, isCompany, isPaid]
  );
  const freeRedirect = useMemo(() => fromUrl ?? Paths.SETTINGS_PAGE, [fromUrl]);
  const to = useMemo(() => (isPaid ? paidRedirect : freeRedirect), [freeRedirect, isPaid, paidRedirect]);

  sessionStorage.removeItem(StorageKey.FromLogin);

  return <Navigate to={to} replace />;
};

export default HomePage;
