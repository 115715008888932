import {
  IBidsData,
  IBidsOrigin,
  IBids,
  IApiBidsData,
  IApiBids,
  IApiBidsCallOffOrigin,
  IApiBidsTenderOrigin,
  IApiBidsManualOrigin,
  ApiBidspaceStatistics,
  BidspaceStatisticsResponse,
  BIDSPACES_STATISTIC_ORDER,
  BidspaceStats,
  AssignedToStatisticsResponse
} from './types';
import {
  isFieldCurrency,
  IBoxFieldCurrency,
  IBoxFieldString,
  IBoxFieldDate
} from 'src/models/procurements/Tender/types';
import { TenderBox } from 'src/models/procurements';
import { isNotUndefined } from 'src/helpers';
import { Stage } from 'src/models/workspace/WorkspaceStatus';
import { BaseWorkspace } from 'src/models/workspace';
import { filterBoxes, convertApiLotsToLots } from 'src/models/procurements/Tender/helpers';
import { getSortedBuyerBoxes } from 'src/models/procurements/helpers';
import { IIcon } from 'src/types/icon';
import { AssignedToCountResponse, BidCategoryStatistics } from '@tendium/prom-types/schema';
import { WORKSPACE_STATUS_CATEGORY_ICON_MAPPER } from 'src/models/workspace/WorkspaceStatus/mappers';

export class BidsData implements IBidsData {
  readonly total: number;
  readonly bids: (IBids | null)[];

  constructor(private readonly apiBidsResponse: IApiBidsData) {
    this.total = apiBidsResponse.total;
    this.bids = this.toBidsData();
  }

  private toBidsData(): (IBids | null)[] {
    const apiBids = this.apiBidsResponse.bids;
    if (!apiBids) {
      return [];
    }
    return apiBids.map(apiBid => {
      return apiBid
        ? {
            id: apiBid.id,
            origin: this.toBidsOrigin(apiBid),
            status: apiBid.status ? new Stage(apiBid.status) : null,
            assignedTo: apiBid.assignedTo ? apiBid.assignedTo.id : null,
            workspace: new BaseWorkspace(apiBid.workspace),
            isCommented: !!apiBid.comments.length,
            isRejected: apiBid.isRejected,
            bidStageId: apiBid.bidStageId,
            itemId: apiBid.itemId,
            webhookStatus: apiBid?.webhookStatus
          }
        : null;
    });
  }

  private toBidsOrigin(apiBid: IApiBids): IBidsOrigin {
    const origin = apiBid.item;
    const originType = apiBid.itemType;
    const general = origin.general;
    const timeline = origin.timeline;
    const lots = convertApiLotsToLots(origin.lots);
    const estBoxes = !!filterBoxes(general.contractValueBoxes, lots).length
      ? filterBoxes(general.contractValueBoxes, lots)
      : undefined;
    const currencyFields =
      ((estBoxes || [])
        .map(box => box.fields && box.fields.filter(field => isFieldCurrency(field)))
        .flat() as IBoxFieldCurrency[]) || [];
    const frameworkBox = !!filterBoxes(general.frameworkAgreement, lots).length
      ? filterBoxes(general.frameworkAgreement, lots)[0]
      : undefined;
    const nameBox = general.name ? new TenderBox(general.name) : undefined;
    const buyerBoxes = filterBoxes(getSortedBuyerBoxes(general.buyerBoxes), lots);
    const publishedBox = timeline?.availableDate ? new TenderBox(timeline.availableDate) : undefined;
    const deadlineBox = !!filterBoxes(timeline.deadline, lots).length
      ? filterBoxes(timeline.deadline, lots)[0]
      : undefined;
    const startBox = timeline?.contractStartAndEndStart ? new TenderBox(timeline?.contractStartAndEndStart) : undefined;
    const endBox = timeline?.contractStartAndEndEnd ? new TenderBox(timeline?.contractStartAndEndEnd) : undefined;

    return {
      id:
        (origin as IApiBidsCallOffOrigin).mailId ??
        (origin as IApiBidsTenderOrigin).id ??
        (origin as IApiBidsManualOrigin).id ??
        '',
      originType,
      name: (nameBox && nameBox.firstField && (nameBox.firstField as IBoxFieldString).string) ?? '',
      buyerBoxes,
      estimatedValue: estBoxes ? (estBoxes[0].firstField as IBoxFieldCurrency) : undefined,
      estimatedValueInSEK: this.getEstimatedValueInCurrency(currencyFields),
      published: (publishedBox && publishedBox.firstField && (publishedBox.firstField as IBoxFieldDate).date) ?? null,
      deadline: (deadlineBox && deadlineBox.firstField && (deadlineBox.firstField as IBoxFieldDate).date) ?? null,
      contractStart: (startBox && startBox.firstField && (startBox.firstField as IBoxFieldDate).date) ?? null,
      contractEnd: (endBox && endBox.firstField && (endBox.firstField as IBoxFieldDate).date) ?? null,
      frameworkAgreement:
        (frameworkBox && frameworkBox.firstField && (frameworkBox.firstField as IBoxFieldString).string) ?? '',
      procurementStatus: (origin as IApiBidsTenderOrigin).procurementStatus ?? undefined,
      customBoxes: origin.custom.map(box => !!box && new TenderBox(box)).filter(isNotUndefined)
    };
  }

  private getEstimatedValueInCurrency(fields: IBoxFieldCurrency[], currency = 'SEK'): number {
    return fields
      .filter(field => field.currency === currency)
      .reduce((total, field) => {
        const amount = field.amount ?? 0;
        return total + amount;
      }, 0);
  }
}

export class AllBidspaceStatistics {
  public statistics: BidspaceStats[];
  public winHitRate: number;

  constructor(private readonly apiBidspaceStatistics: BidspaceStatisticsResponse) {
    this.statistics = Object.keys(WORKSPACE_STATUS_CATEGORY_ICON_MAPPER)
      .map(key => {
        const typedKey = key as keyof ApiBidspaceStatistics;
        return {
          key: typedKey,
          value: new BidspaceCategoryStatistic(this.apiBidspaceStatistics.getBidCategoryStatistics[typedKey], typedKey)
        };
      })
      .sort((a, b) => {
        return (
          BIDSPACES_STATISTIC_ORDER.findIndex(order => order === a.key) -
          BIDSPACES_STATISTIC_ORDER.findIndex(order => order === b.key)
        );
      });
    this.winHitRate = this.toWinHitRate();
  }

  private toWinHitRate(): number {
    const won = this.apiBidspaceStatistics.getBidCategoryStatistics.Won.count;
    const lost = this.apiBidspaceStatistics.getBidCategoryStatistics.Lost.count;
    return won / (won + lost);
  }
}

export class BidspaceCategoryStatistic {
  public readonly count: number;
  public readonly amount: number;
  public readonly icon: IIcon;
  constructor(bidCategoryStatistics: BidCategoryStatistics, field: keyof ApiBidspaceStatistics) {
    this.count = bidCategoryStatistics.count;
    this.amount = bidCategoryStatistics.amount;
    this.icon = WORKSPACE_STATUS_CATEGORY_ICON_MAPPER[field];
  }
}

export class AllAssignedToBidspacesStatistics {
  statistics: AssignedToCount[];

  constructor(private readonly apiBidspaceStatistics: AssignedToStatisticsResponse) {
    this.statistics = this.apiBidspaceStatistics.getAssignedToStatistics.map(stat => new AssignedToCount(stat));
  }
}
export class AssignedToCount {
  public id?: string;
  public bidCount: number;
  public assignedTo?: string;

  constructor(private readonly apiAssignedToCount: AssignedToCountResponse) {
    this.id = this.apiAssignedToCount.id ?? '';
    this.bidCount = this.apiAssignedToCount.bidCount;
    this.assignedTo = this.apiAssignedToCount.assignedTo?.id;
  }
}
