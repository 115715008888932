import { IApiBid } from '../BidFull/types';
import { IWorkspaceStatus, IApiWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { ProcurementStatus } from 'src/models/procurements/types';
import { DndType } from 'src/types/dnd';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { IInboxEmail } from 'src/models/callOffs/types';
import { IBaseWorkspace } from 'src/models/workspace/types';
import {
  IBoxFieldCurrency,
  IApiTenderLot,
  IApiTender,
  IApiTenderBox,
  ITenderTimeline,
  ITenderGeneral,
  ITenderBox
} from 'src/models/procurements/Tender/types';
import { QueryResult, Reference } from '@apollo/client';
import { isObject } from 'src/helpers';
import { WebhookStatus } from 'src/models/company/Webhooks/types';
import { ISearchBidsRequest } from './hooks';
import { AssignedToCountResponse, BidCategoryStatistics } from '@tendium/prom-types/schema';
import { AllAssignedToBidspacesStatistics, AllBidspaceStatistics, BidspaceCategoryStatistic } from '.';

export enum BidsColumn {
  actions = 'actions',
  status = 'Status',
  bidspace = 'Bidspace',
  published = 'Published',
  title = 'Title',
  buyer = 'Buyer',
  deadline = 'Deadline',
  contractStart = 'ContractStart',
  contractEnd = 'ContractEnd',
  frameworkAgreement = 'FrameworkAgreement',
  infoActions = 'infoActions'
}

export function isBidSortField(u: unknown): boolean {
  return (
    typeof u === 'string' &&
    Object.values(BidsColumn).some(c => c === u) &&
    SORTABLE_BIDS_COLUMNS.includes(u as BidsColumn)
  );
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IBidsLoadArgs {
  pagination?: { offset: number; limit: number };
}

export const INITIAL_BIDS_SEARCH_ARGS: IBidsSearchArgs = {
  offset: 0,
  amount: 30,
  sortOnField: { name: BidsColumn.deadline, order: SortOrder.ASC },
  rejected: false
};

export interface IBidsSearchArgs {
  offset?: number;
  amount?: number;
  search?: string;
  rejected?: boolean;
  sortOnField?: IBidSorting;
}

export interface IBidSorting {
  name: BidsColumn;
  order: SortOrder;
}

export interface IBidsOrigin {
  originType: BidItemType;
  id: string;
  buyerBoxes: ITenderBox[];
  name: string;
  estimatedValue?: IBoxFieldCurrency;
  estimatedValueInSEK: number;
  frameworkAgreement: string;
  published: number | null;
  contractStart: number | null;
  contractEnd: number | null;
  deadline: number | null;
  procurementStatus?: ProcurementStatus[];
  customBoxes: ITenderBox[];
}

export interface IBidsData {
  readonly total: number;
  readonly bids: (IBids | null)[];
}

export interface IBids {
  id: string;
  itemId: string;
  origin: IBidsOrigin;
  workspace: IBaseWorkspace;
  status: IWorkspaceStatus | null;
  assignedTo: string | null;
  isCommented: boolean;
  isRejected: boolean;
  bidStageId: string | null;
  webhookStatus?: WebhookStatus;
}

export type IBidsCached = {
  bids: Reference[];
  total: number;
  totalBids?: number;
};

export interface IApiBidsData {
  total: number;
  bids: IApiBids[];
}

export interface IApiShortBid {
  id: string;
  itemId: string;
  workspace: { id: string };
  status: { id: string };
  bidStageId: string;
  webhookStatus?: WebhookStatus;
}

// FIXME: @ NAMING --> change it!
// only when everything totally works !!
export interface IApiBids extends IApiBid {
  item: IApiBidsOrigin;
  itemType: BidItemType;
  workspace: IBaseWorkspace;
  status: IApiWorkspaceStatus | null;
  isRejected: boolean;
}

export interface IApiBidsOriginGeneric {
  timeline: Pick<
    ITenderTimeline<IApiTenderBox>,
    'availableDate' | 'contractStartAndEndStart' | 'deadline' | 'contractStartAndEndEnd'
  >;
  general: Pick<ITenderGeneral<IApiTenderBox>, 'name' | 'buyerBoxes' | 'contractValueBoxes' | 'frameworkAgreement'>;
  lots: IApiTenderLot[];
  custom: IApiTenderBox[];
}
export interface IApiBidsTenderOrigin extends IApiBidsOriginGeneric, Pick<IApiTender, 'id' | 'procurementStatus'> {}
export interface IApiBidsCallOffOrigin extends Pick<IInboxEmail, 'mailId'>, IApiBidsOriginGeneric {}
export interface IApiBidsManualOrigin extends IApiBidsOriginGeneric {
  id: string;
}
export type IApiBidsOrigin = IApiBidsTenderOrigin | IApiBidsCallOffOrigin | IApiBidsManualOrigin;

export interface IBidsBoardDndItem {
  type: DndType.BidsBoardItem;
  dndItem: IBids;
  index?: number;
}
export function isBidsBoardDndItem(u: unknown): u is IBidsBoardDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.BidsBoardItem;
}

export interface IBidsTableDndItem {
  type: DndType.BidsTableItem;
  dndItem: IBids;
  bidIds?: IBidIdentifier[];
}
export function isBidsTableDndItem(u: unknown): u is IBidsTableDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.BidsTableItem;
}

export const SORTABLE_BIDS_COLUMNS = [
  BidsColumn.status,
  BidsColumn.published,
  BidsColumn.title,
  BidsColumn.buyer,
  BidsColumn.deadline,
  BidsColumn.contractStart,
  BidsColumn.contractEnd,
  BidsColumn.frameworkAgreement
];

export const SORTABLE_KANBAN_BIDS_COLUMNS = [
  BidsColumn.published,
  BidsColumn.title,
  BidsColumn.buyer,
  BidsColumn.deadline
];

export const SELECTABLE_BIDS_COLUMNS = [
  BidsColumn.status,
  BidsColumn.published,
  BidsColumn.title,
  BidsColumn.buyer,
  BidsColumn.deadline,
  BidsColumn.contractStart,
  BidsColumn.contractEnd,
  BidsColumn.frameworkAgreement
];

export interface IBidIdentifier {
  id: string;
  wsId: string;
}

export interface ILinkedBidIdentifier extends IBidIdentifier {
  originId: string;
}

export type BidspaceStatisticsResponse = {
  getBidCategoryStatistics: ApiBidspaceStatistics;
};

export type ApiBidspaceStatistics = {
  [WorkspaceStatusCategory.InProgress]: BidCategoryStatistics;
  [WorkspaceStatusCategory.Interesting]: BidCategoryStatistics;
  [WorkspaceStatusCategory.Lost]: BidCategoryStatistics;
  [WorkspaceStatusCategory.Submitted]: BidCategoryStatistics;
  [WorkspaceStatusCategory.Won]: BidCategoryStatistics;
};

export interface NewBidspaceRequest extends ISearchBidsRequest {
  assignedToIds?: string[];
  bidItemTypes?: BidItemType[];
  statusCategories?: WorkspaceStatusCategory[];
  workspaceIds?: string[];
}

export const BIDSPACES_STATISTIC_ORDER: (keyof ApiBidspaceStatistics)[] = [
  WorkspaceStatusCategory.Interesting,
  WorkspaceStatusCategory.InProgress,
  WorkspaceStatusCategory.Submitted,
  WorkspaceStatusCategory.Won,
  WorkspaceStatusCategory.Lost
];

export enum BidspaceQueryParams {
  bidspaceId = 'bidspaceId',
  category = 'category',
  assignedTo = 'assignedTo',
  bidType = 'bidType',
  search = 'search',
  sort = 'sort'
}

export type BidspaceStats = {
  key: keyof ApiBidspaceStatistics;
  value: BidspaceCategoryStatistic;
};

export interface ApiGetTotalBidsResponse {
  getBids: { totalBids: number };
}

export interface ApiGetTotalBidsRequest {
  stageIds: string[];
  offset?: number;
  amount?: number;
}

export interface BidspaceStatisticsData
  extends Omit<QueryResult<BidspaceStatisticsResponse, NewBidspaceRequest>, 'data'> {
  data?: AllBidspaceStatistics;
}

export type AssignedToStatisticsResponse = {
  getAssignedToStatistics: AssignedToCountResponse[];
};

export interface AssignedToStatisticsData
  extends Omit<QueryResult<AssignedToStatisticsResponse, NewBidspaceRequest>, 'data'> {
  data?: AllAssignedToBidspacesStatistics;
  fetchingMore: boolean;
}

export const DASHBOARD_TABLE_PAGE_SIZE = 4;
