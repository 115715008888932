import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faCoin, faTimer } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';
import { IdentifiableTenderObject } from '../../..';
import { Paths } from 'src/pages/paths';
import { toDateRangeStr } from 'src/helpers/dates';
import { toCurrencyValue } from 'src/models/procurements/Billing/helpers';
interface Props {
  tenderObject: IdentifiableTenderObject;
}

export const TenderListItem: FC<Props> = ({ tenderObject }) => {
  const { id, tender } = tenderObject;

  return (
    <a href={`${Paths.TENDER_ROUTE}${id}`} rel="noopener noreferrer" target="_blank">
      <div className={styles.item} key={tender.title}>
        <span className={styles.title}>{tender.title}</span>
        <span className={styles.info}>
          <span>
            <FontAwesomeIcon icon={faTimer} className={styles.icon} />
            {toDateRangeStr([tender.contractPeriod?.startDate, tender.contractPeriod?.endDate])}
          </span>
          <span>
            <FontAwesomeIcon icon={faCoin} className={styles.icon} />
            {toCurrencyValue(tender.value?.amount, tender.value?.currency, true)}
          </span>
        </span>
      </div>
    </a>
  );
};
