import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Tooltip, NavSidebarLink, NavMenu, NavMenuTitle, NavMenuLinks, Tag } from 'src/common';
import WorkspacesList from './WorkspacesList';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { Paths } from 'src/pages/paths';
import { useLocation } from 'react-router-dom';
import {
  faSparkles,
  faThList,
  faStar,
  faArchive,
  faBalanceScaleRight,
  faEnvelope,
  faBuilding,
  faCalendarClock,
  faCommentsQuestionCheck,
  faBooks,
  faSquareKanban
} from '@fortawesome/pro-light-svg-icons';
import {
  faSparkles as faSparklesSolid,
  faThList as faThListSolid,
  faStar as faStarSolid,
  faArchive as faArchiveSolid,
  faBalanceScaleRight as faBalanceScaleRightSolid,
  faEnvelope as faEnvelopeSolid,
  faBuilding as faBuildingSolid,
  faCalendarClock as faCalendarClockSolid,
  faCommentsQuestionCheck as faCommentsQuestionCheckSolid,
  faBooks as faBooksSolid,
  faSquareKanban as faSquareKanbanSolid
} from '@fortawesome/pro-solid-svg-icons';
import { NavSidebar, useNavSidebar } from '../NavSidebar';
import InboxesList from './InboxesList';
import { useLoadInboxesNav } from 'src/models/callOffs/hooks';
import { TagType } from 'src/common/Tag';
import { Language } from '@tendium/prom-types';
import useLanguage from 'src/common/hooks/useLanguage';
import { Ownerships, ownership } from 'src/models/ownership';
import TendersDndLayer from 'src/pages/TendersPage/DndLayer';
import MpsList from './MpsList';
import Accordion from 'src/common/Accordion';
import { PageName, PageView } from 'src/models/users/Preferences/types';
import { useTendersNavViewState } from 'src/models/users/Preferences/hooks';
import ContentLibraryRoomList from './ContentLibraryRoomList';
import { useNavigationState } from 'src/helpers';

interface Props {
  isNarrow?: boolean;
}

const TendersNavContent: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [sidebarIsOpen] = useNavSidebar();
  const { language } = useLanguage();

  const [monitoringNavView, projectBidNavView, analyticsNavView, setTenderNavView] = useTendersNavViewState();

  const isRamaFeature = useFeatureFlag(FeatureFlag.Rama_Visualizations);
  const isDocLibFeature = useFeatureFlag(FeatureFlag.Interactions_DocumentLibrary);
  const isCallOffFeature = useFeatureFlag(FeatureFlag.Mercury);
  const isBuyerProfilesHomePageAvailable = useFeatureFlag(FeatureFlag.AnalyticsHomepage);
  const isExpiringContractsPageAvailable = useFeatureFlag(FeatureFlag.AnalyticsExpiringContracts);
  const isAnalyticsExpiringContractsLight = useFeatureFlag(FeatureFlag.AnalyticsExpiringContractsLight);
  const isExpiringContractsMenuAvailable = ownership.name !== Ownerships.Clira;
  const isMonitoringProfilesFeature = useFeatureFlag(FeatureFlag.MonitoringProfiles);
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);
  const isAnalyticsAvailable = isBuyerProfilesHomePageAvailable || isExpiringContractsMenuAvailable || isRamaFeature;
  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);
  const isAllTendersFeature = useFeatureFlag(FeatureFlag.AllTenders);
  const isProjectsAndBidsAvailable =
    isBidspacesFeature || isDocLibFeature || ownership.isTendium || isBiddingGPTContentLibrary;

  const fromPath = useNavigationState();

  const { data: inboxes } = useLoadInboxesNav(!isCallOffFeature);
  const inboxesExist = useMemo(() => {
    if (!isCallOffFeature) {
      return false;
    }
    return inboxes && !!inboxes.length;
  }, [inboxes, isCallOffFeature]);

  const onToggle = (page: PageName, view: PageView): void => {
    if (view === PageView.expanded) {
      setTenderNavView(page, PageView.collapsed);
    } else if (view === PageView.collapsed) {
      setTenderNavView(page, PageView.expanded);
    }
  };

  return (
    <>
      <NavMenu>
        <Accordion
          label={<NavMenuTitle isOpen={sidebarIsOpen}>{t('Navigation.monitoring')} </NavMenuTitle>}
          initialValue={monitoringNavView === PageView.expanded}
          onToggleComplete={() => onToggle(PageName.tenderNavMonitoring, monitoringNavView)}
          className={!sidebarIsOpen ? styles.accordion : undefined}
          labelVisible={sidebarIsOpen}
        >
          <NavMenuLinks>
            {isMonitoringProfilesFeature && (
              <>
                <NavSidebarLink
                  to={{ pathname: Paths.MONITORING, state: { from: fromPath } }}
                  title={t('Tenders.monitoringProfiles')}
                  icon={{ defaultIcon: faSparkles, activeIcon: faSparklesSolid }}
                  isActive={pathname === Paths.MONITORING}
                  isExpanded={sidebarIsOpen}
                />
                <MpsList sidebarIsOpen={sidebarIsOpen} />
              </>
            )}
            {isCallOffFeature && (
              <div className={styles.menuItems}>
                <NavSidebarLink
                  to={{ pathname: Paths.CALL_OFFS, state: { from: fromPath } }}
                  title={t('Navigation.callOffs')}
                  icon={{ defaultIcon: faEnvelope, activeIcon: faEnvelopeSolid }}
                  isActive={pathname === Paths.CALL_OFFS}
                  isExpanded={sidebarIsOpen}
                />
                {inboxesExist && <InboxesList sidebarIsOpen={sidebarIsOpen} />}
              </div>
            )}
            {isAllTendersFeature && (
              <NavSidebarLink
                to={{ pathname: Paths.ALL_TENDERS, state: { from: fromPath } }}
                title={t('Navigation.allTenders')}
                icon={{ defaultIcon: faThList, activeIcon: faThListSolid }}
                isActive={pathname === Paths.ALL_TENDERS}
                isExpanded={sidebarIsOpen}
              />
            )}
            <NavSidebarLink
              to={{ pathname: Paths.STARRED_TENDERS, state: { from: fromPath } }}
              title={t('Navigation.starred')}
              icon={{ defaultIcon: faStar, activeIcon: faStarSolid }}
              isActive={pathname === Paths.STARRED_TENDERS}
              isExpanded={sidebarIsOpen}
            />
          </NavMenuLinks>
        </Accordion>
      </NavMenu>
      {!!isProjectsAndBidsAvailable && (
        <NavMenu className={styles.workspaceMenu}>
          <Accordion
            label={<NavMenuTitle isOpen={sidebarIsOpen}>{t('Navigation.projectAndBids')} </NavMenuTitle>}
            initialValue={projectBidNavView === PageView.expanded}
            onToggleComplete={() => onToggle(PageName.tenderNavProjectBid, projectBidNavView)}
            className={!sidebarIsOpen ? styles.accordion : undefined}
            labelVisible={sidebarIsOpen}
          >
            <NavMenuLinks>
              {!!isBidspacesFeature && (
                <>
                  <NavSidebarLink
                    to={{ pathname: Paths.BIDSPACES, state: { from: fromPath } }}
                    title={t('Navigation.bidSpaces')}
                    icon={{ defaultIcon: faSquareKanban, activeIcon: faSquareKanbanSolid }}
                    isActive={pathname === Paths.BIDSPACES}
                    isExpanded={sidebarIsOpen}
                  />
                  <WorkspacesList sidebarIsOpen={sidebarIsOpen} />
                </>
              )}
              {isBiddingGPTContentLibrary && (
                <>
                  <NavSidebarLink
                    to={{ pathname: Paths.CONTENT_LIBRARY, state: { from: fromPath } }}
                    title={t('ContentLibrary.title')}
                    icon={{ defaultIcon: faBooks, activeIcon: faBooksSolid }}
                    isActive={pathname === Paths.CONTENT_LIBRARY}
                    isExpanded={sidebarIsOpen}
                  />
                  <ContentLibraryRoomList sidebarIsOpen={sidebarIsOpen} />
                </>
              )}
              {ownership.isTendium && (
                <NavSidebarLink
                  to={{ pathname: Paths.BIDDING_GPT, state: { from: fromPath } }}
                  title={t('Navigation.biddingGPT')}
                  icon={{ defaultIcon: faCommentsQuestionCheck, activeIcon: faCommentsQuestionCheckSolid }}
                  isActive={pathname === Paths.BIDDING_GPT}
                  isExpanded={sidebarIsOpen}
                />
              )}
              {!!isDocLibFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.DOCS_LIB, state: { from: fromPath } }}
                  title={t('Navigation.documentLibrary')}
                  icon={{ defaultIcon: faArchive, activeIcon: faArchiveSolid }}
                  isActive={pathname === Paths.DOCS_LIB}
                  isExpanded={sidebarIsOpen}
                />
              )}
            </NavMenuLinks>
          </Accordion>
        </NavMenu>
      )}
      {isAnalyticsAvailable && (
        <NavMenu>
          <Accordion
            label={<NavMenuTitle isOpen={sidebarIsOpen}>{t('Navigation.analytics')}</NavMenuTitle>}
            initialValue={analyticsNavView === PageView.expanded}
            onToggleComplete={() => onToggle(PageName.tenderNavAnalytics, analyticsNavView)}
            className={!sidebarIsOpen ? styles.accordion : undefined}
            labelVisible={sidebarIsOpen}
          >
            <NavMenuLinks>
              {(isExpiringContractsMenuAvailable || isAnalyticsExpiringContractsLight) && (
                <NavSidebarLink
                  to={{
                    pathname: `${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_PAGE}`,

                    state: { from: fromPath }
                  }}
                  title={
                    <Tooltip
                      title={
                        !isExpiringContractsPageAvailable && sidebarIsOpen && language !== Language.fi
                          ? t('ExpiringContracts.newTagTooltip')
                          : null
                      }
                      placement={'right'}
                      className={styles.expiringContractsTooltipWrapper}
                    >
                      <span className={styles.expiringContractsLabel}>{t('Navigation.expiringContracts')}</span>
                      {sidebarIsOpen && language !== Language.fi ? (
                        <Tag
                          type={
                            isAnalyticsExpiringContractsLight && !isExpiringContractsPageAvailable
                              ? TagType.Light
                              : TagType.New
                          }
                        />
                      ) : null}
                    </Tooltip>
                  }
                  icon={{ defaultIcon: faCalendarClock, activeIcon: faCalendarClockSolid }}
                  isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_PAGE}`}
                  isExpanded={sidebarIsOpen}
                  className={styles.expiringContactsLink}
                  isDisabled={!isExpiringContractsPageAvailable && !isAnalyticsExpiringContractsLight}
                />
              )}
              {!!isBuyerProfilesHomePageAvailable && (
                <NavSidebarLink
                  to={{ pathname: `${Paths.ANALYTICS_PAGE}${Paths.BUYERS_PAGE}`, state: { from: fromPath } }}
                  title={t('Navigation.buyerProfiles')}
                  icon={{ defaultIcon: faBuilding, activeIcon: faBuildingSolid }}
                  isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.BUYERS_PAGE}`}
                  isExpanded={sidebarIsOpen}
                />
              )}
              {!!isRamaFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.RAMA, state: { from: fromPath } }}
                  title={t('Navigation.rama')}
                  icon={{ defaultIcon: faBalanceScaleRight, activeIcon: faBalanceScaleRightSolid }}
                  isActive={pathname === Paths.RAMA}
                  isExpanded={sidebarIsOpen}
                />
              )}
            </NavMenuLinks>
          </Accordion>
        </NavMenu>
      )}
    </>
  );
};

export const TendersNav: FC<Props> = ({ isNarrow }) => {
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);

  return (
    <NavSidebar type={isNarrow ? 'min' : undefined}>
      {isBidspacesFeature && <TendersDndLayer />}
      <TendersNavContent />
    </NavSidebar>
  );
};
export default TendersNav;
