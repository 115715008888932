import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { UserPicker } from 'src/common';
import { useActiveUsersAndTeams } from 'src/models/users/hooks';
import { useBidsFilterVars } from 'src/models/bids/Bids/hooks';
import { trackABFilter } from 'src/segment/events/allBidspaces';

export interface Props {
  // eventSource: ContentLibraryEventSource;
}
const BidspacesFilterAssignedTo: FC<Props> = () => {
  const { t } = useTranslation();
  const usersAndTeams = useActiveUsersAndTeams();
  const { currentVars, updateVars } = useBidsFilterVars();

  const selectedAssignedUsers = useMemo(
    () => (currentVars.assignedTo ? currentVars.assignedTo : undefined),
    [currentVars]
  );

  const onChangeAssigned = useCallback(
    ({ id: assignedTo }: { id: string }, checked?: boolean) => {
      const previousValue = selectedAssignedUsers || [];
      const currentValue = checked ? [...previousValue, assignedTo] : previousValue.filter(item => item !== assignedTo);
      updateVars?.({ assignedTo: currentValue });

      if (checked) trackABFilter('Assigned to');
    },
    [selectedAssignedUsers, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedAssignedUsers?.length })}>
      <UserPicker
        label={t('BidSpaces.Filters.assignedTo')}
        users={usersAndTeams}
        onSelect={onChangeAssigned}
        selected={selectedAssignedUsers}
        className={styles.label}
        userPickerType="multiple"
        renderMode={'button'}
      />
    </div>
  );
};

export default BidspacesFilterAssignedTo;
