import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import numeral from 'numeral';
import { II18nService, ServerTranslations, Translations } from './types';
import { MyLanguageDetector } from './LanguageDetector';
import dayjs from 'src/helpers/dayjs';
import { Config } from 'src/config/config';
import 'dayjs/locale/sv';

import updateLocale from 'dayjs/plugin/updateLocale';
import common from 'src/i18n/common/en.json';
import procurements from 'src/i18n/procurements/en.json';
import country from 'src/i18n/country/en.json';
import cpvCodes from 'src/i18n/cpvCodes/en.json';
import nutsCodes from 'src/i18n/nutsCodes/en.json';
import cognitoErrors from 'src/i18n/cognito/en.json';

import commonSV from 'src/i18n/common/sv.json';
import procurementsSV from 'src/i18n/procurements/sv.json';
import countrySV from 'src/i18n/country/sv.json';
import cpvCodesSV from 'src/i18n/cpvCodes/sv.json';
import nutsCodesSV from 'src/i18n/nutsCodes/sv.json';
import cognitoErrorsSV from 'src/i18n/cognito/sv.json';

import commonFI from 'src/i18n/common/fi.json';
import procurementsFI from 'src/i18n/procurements/fi.json';
import countryFI from 'src/i18n/country/fi.json';
import cpvCodesFI from 'src/i18n/cpvCodes/fi.json';
import nutsCodesFI from 'src/i18n/nutsCodes/fi.json';
import cognitoErrorsFI from 'src/i18n/cognito/fi.json';

import { trackMissingTranslation } from 'src/segment/events/translation';
import { Language } from '@tendium/prom-types';
import { ownership } from 'src/models/ownership';

dayjs.extend(updateLocale);

dayjs.updateLocale('sv', {
  monthsShort: 'jan_feb_mars_april_maj_juni_juli_aug_sept_okt_nov_dec'.split('_')
});

dayjs.updateLocale('en', {
  monthsShort: 'Jan_Feb_Mar_Apr_May_June_July_Aug_Sept_Oct_Nov_Dec'.split('_')
});

export const Languages = [
  { id: Language.en, title: 'English' },
  { id: Language.sv, title: 'Swedish' },
  { id: Language.fi, title: 'Finnish' }
];
export class I18nService implements II18nService {
  public readonly i18n: i18n;

  constructor() {
    this.i18n = i18next.createInstance();
    this.i18n.on('languageChanged', lng => {
      this.handleLocaleChanges(lng);
    });

    this.i18n
      .use(MyLanguageDetector) // Language detector allow us to chose correct translation by default based on browser settings or ip or coordinates
      .use(initReactI18next)
      .init({
        debug: import.meta.env.MODE === 'development',
        defaultNS: 'common',
        detection: {
          caches: ['localStorage'],
          lookupLocalStorage: 'i18nextLng',
          order: ['localStorage', 'navigator']
        },
        react: {
          nsMode: 'fallback'
        },
        whitelist: [Language.en, Language.sv, Language.fi],
        fallbackLng: {
          default: [Language.en],
          eng: [Language.en],
          swe: [Language.sv],
          fin: [Language.fi]
        },
        interpolation: {
          format: this.format,
          defaultVariables: {
            ...this.ownershipVars
          }
        },
        ns: Object.values(Translations),
        nsSeparator: '|',
        resources: {
          [Language.en]: {
            common,
            procurements,
            country,
            cpvCodes,
            nutsCodes,
            cognitoErrors
          },
          [Language.sv]: {
            common: commonSV,
            procurements: procurementsSV,
            country: countrySV,
            cpvCodes: cpvCodesSV,
            nutsCodes: nutsCodesSV,
            cognitoErrors: cognitoErrorsSV
          },
          [Language.fi]: {
            common: commonFI,
            procurements: procurementsFI,
            country: countryFI,
            cpvCodes: cpvCodesFI,
            nutsCodes: nutsCodesFI,
            cognitoErrors: cognitoErrorsFI
          }
        },
        saveMissing: true
      })
      .then(() => this.handleLocaleChanges(this.i18n.language))
      .catch(err => console.error(err));
    this.i18n.on('missingKey', this.onI18nextMissingKey);
  }

  private format = (value: unknown, format?: string): string => {
    if (format && format.startsWith('numeral:')) {
      return this.numeralFormat(String(value), format);
    }

    if (value instanceof Date && format) {
      if (format.startsWith('utc:')) {
        return this.utcDateFormat(value, format.replace('utc:', ''));
      } else {
        return this.dateFormat(value, format);
      }
    }

    return String(value);
  };

  private dateFormat = (value: Date, format?: string): string => {
    return dayjs(value).format(format);
  };
  private utcDateFormat = (value: Date, format?: string): string => {
    return dayjs.utc(value).format(format);
  };

  private numeralFormat = (value: string, format: string): string => {
    const numeralFormat = format.substring(8);
    // hack for supporting space in format
    if (numeralFormat.includes(' ')) {
      numeral.localeData().delimiters.thousands = ' ';
      return numeral(value).format(numeralFormat.replace(' ', ','));
    }
    numeral.localeData().delimiters.thousands = ',';
    return numeral(value).format(numeralFormat);
  };

  private onI18nextMissingKey = (lngs: string[], namespace: string, key: string): void => {
    // here we can log companyErrors. E.g. pass a notification to bugsnag
    // console.warn('Key has no translation', { lngs, namespace, key, res });
    trackMissingTranslation(lngs, namespace, key);
  };

  private handleLocaleChanges(localeString: string): void {
    // here we can patch external libraries to use new language
    // e.g. below is a version for moment js
    switch (localeString) {
      case Language.en:
        dayjs.locale(Language.en);
        break;
      case Language.sv:
        dayjs.locale(Language.sv);
        break;
      case Language.fi:
        dayjs.locale(Language.fi);
        break;
      default:
        dayjs.locale(Language.en);
    }
  }

  public async loadExternalResources(): Promise<void> {
    const { I18NextURL } = Config;
    if (!I18NextURL) {
      throw new Error('I18NextURL is not set');
    }
    const translationsResult = await fetch(I18NextURL);
    const translations = (await translationsResult.json()) as ServerTranslations;
    if (typeof translations !== 'object') {
      throw new Error('Data has incorrect format');
    }
    if (!translations) {
      throw new Error('Data is not loaded or has incorrect format');
    }

    for (const [lng, lngTranslations] of Object.entries(translations)) {
      for (const [namespace, namespaceData] of Object.entries(lngTranslations)) {
        if (namespaceData) {
          this.i18n.addResourceBundle(lng, namespace, namespaceData, false, false);
        }
      }
    }
  }
  private get ownershipVars(): { [key: string]: string } {
    return {
      ownership: ownership.name,
      supportEmail: ownership.links.SUPPORT_EMAIL
    };
  }
}

const i18nService = new I18nService();

export default i18nService;
