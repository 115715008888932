import React, { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faSparkles } from '@fortawesome/pro-thin-svg-icons';
import { useLazyPeriodTransactions, useTenderTransactionsData } from 'src/models/procurements/Billing/hooks';
import { DISPLAY_SUPPLIER_COUNT, PeriodTransaction } from 'src/models/procurements/Billing/types';
import { BillingBarStackChart, BillingCumulativeLineChart } from 'src/shared/Tender/Billing';
import { formatDate, getDatesFromDuration, getDurationFromPeriod, PeriodType } from 'src/helpers/dates';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { BlockTitle } from 'src/shared/Blocks';
import { EmptyFilter } from 'src/common';
import MonthlyPaymentTable from '../MonthlyPaymentTable';
import { billing_title_icon, SupplierTransactions } from '../types';
import SupplierList from '../SupplierList';
import boxStyles from 'src/shared/Tender/Boxes/EditableBox/index.module.scss';
import styles from '../index.module.scss';

interface Props {
  tenderId?: string;
  className?: string;
  boxStyle?: boolean;
}

export const BillingChartsComponent: FC<Props> = ({ tenderId, className, boxStyle = true }) => {
  const { t } = useTranslation();
  const { vars, data, loading, error } = useTenderTransactionsData(tenderId);
  const suppliers = useMemo(() => data?.organisations ?? [], [data?.organisations]);
  const currency = useMemo(() => data?.currency ?? 'SEK', [data?.currency]);
  const [fetchMonthlyData] = useLazyPeriodTransactions();
  const [current, setCurrent] = useState<SupplierTransactions | undefined>(undefined);
  const [isMonthViewLoading, setIsMonthViewLoading] = useState(false);

  const onClick = useCallback(
    async (d: PeriodTransaction) => {
      const duration = getDurationFromPeriod(d.period, vars?.periodType as PeriodType);
      const dateFrom = formatDate(duration[0]);
      const dateTo = formatDate(duration[1]);
      const periodType = 'month';
      setIsMonthViewLoading(true);
      setCurrent(undefined);
      const { data } = await fetchMonthlyData({
        variables: {
          buyerOrgIds: vars?.buyerOrgIds || [],
          supplierOrgIds: [d.supplierOrgId],
          dateFrom,
          dateTo,
          periodType
        }
      });
      setIsMonthViewLoading(false);
      const transactions = data?.getTransactionsPeriod?.transactions ?? [];
      const supplier = suppliers.find(supplier => supplier.organisationNumber === d.supplierOrgId);
      if (!!transactions.length && supplier) {
        setCurrent({
          period: d.period,
          dates: getDatesFromDuration([dateFrom, dateTo], periodType),
          transactions,
          supplier
        });
      }
    },
    [fetchMonthlyData, suppliers, vars?.buyerOrgIds, vars?.periodType]
  );
  const theClassName = classNames({
    [styles.container]: !boxStyle,
    [boxStyles.editableBox]: boxStyle,
    [className ?? '']: className
  });
  const displaySupplierLimits = useMemo(() => {
    const reqSupplierCount = vars?.supplierOrgIds?.length ?? 0;
    const displaySupplierCount = vars?.displaySupplierCount ?? DISPLAY_SUPPLIER_COUNT;
    return reqSupplierCount > displaySupplierCount ? displaySupplierCount : 0;
  }, [vars?.displaySupplierCount, vars?.supplierOrgIds?.length]);

  const isChartVisible = useMemo(
    () => !!(data?.transactions?.length || loading || error),
    [data?.transactions?.length, error, loading]
  );

  return (
    <>
      <BlockTitle className={styles.header} title={t('Tenders.Billing.title')} icon={billing_title_icon} />
      {isChartVisible ? (
        <>
          <BillingCumulativeLineChart
            className={theClassName}
            loading={loading}
            error={!!error}
            data={data}
            vars={vars}
            displaySupplierLimits={displaySupplierLimits}
          />
          <BillingBarStackChart
            className={theClassName}
            loading={loading}
            error={!!error}
            data={data}
            vars={vars}
            displaySupplierLimits={displaySupplierLimits}
            onClick={onClick}
          >
            {!loading && (
              <MonthlyPaymentTable currency={currency} data={current} vars={vars} loading={isMonthViewLoading} />
            )}
          </BillingBarStackChart>
          {!!vars?.supplierOrgIds.length && !!vars?.buyerOrgIds.length && (
            <SupplierList
              vars={vars}
              suppliers={suppliers}
              tenderId={tenderId}
              className={theClassName}
              loading={loading}
            />
          )}
        </>
      ) : (
        <EmptyFilter
          className={styles.emptyState}
          title={''}
          desc={t('Tenders.Billing.emptyState')}
          icon={faSparkles}
        />
      )}
    </>
  );
};

export const BillingCharts: FC<Props> = props => {
  const isFeatureAvailable = useFeatureFlag(FeatureFlag.Analytics_Billing_During_Contract);
  return isFeatureAvailable ? <BillingChartsComponent {...props} /> : null;
};

export default BillingCharts;
