import { UserOwnership as Ownerships, Role } from '@tendium/prom-types';
import { ByggfaktaLogoIcon, CliraLogoIcon, TendiumLogoIcon } from 'src/common/icons';
import { BF_PARTNER_PAYMENT_ROLES, CLR_PAYMENT_ROLES, TENDIUM_PAYMENT_ROLES } from '../users/Roles/types';

export { Ownerships };

export interface OwnershipLinks {
  POLICY_URL: string;
  LANDING_URL: string;
  CONTACT_URL: string;
  SUPPORT_EMAIL: string;
}

export interface OwnershipImages {
  buyerProfile?: string;
}

export interface OAuth {
  redirectSignIn: string[];
  redirectSignOut: string[];
}

export enum ThemeMode {
  light = 'light',
  dark = 'dark'
}

export interface LogoProps {
  mode?: ThemeMode;
  isShort?: boolean;
  className?: string;
}

export interface Favicon {
  svg: string;
  ico: string;
}

export interface PaymentRoles {
  roles: string[];
  defaultRoles: Role[];
}

export interface OwnershipConfig {
  domain?: string;
  qrCodeTitle?: string;
  logo: React.FC<LogoProps>;
  favicon: Favicon;
  images?: OwnershipImages;
  links: OwnershipLinks;
  oAuth: OAuth;
  paymentRoles: PaymentRoles;
}

export const OWNERSHIPS: { [key: string]: OwnershipConfig } = {
  [Ownerships.Tendium]: {
    logo: TendiumLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/favicon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/favicon-tendium.svg`
    },
    images: {
      buyerProfile: `${import.meta.env.BASE_URL}images/hero_organisations.png`
    },
    links: {
      POLICY_URL: 'https://tendium.ai/privacy-policy',
      LANDING_URL: 'https://tendium.ai',
      CONTACT_URL: 'https://media.tendium.com/sv/hor-av-dig-till-oss',
      SUPPORT_EMAIL: 'support@tendium.com'
    },
    oAuth: {
      redirectSignIn: [import.meta.env.VITE_SIGN_IN_REDIRECT],
      redirectSignOut: [import.meta.env.VITE_SIGN_OUT_REDIRECT]
    },
    paymentRoles: {
      roles: TENDIUM_PAYMENT_ROLES,
      defaultRoles: [Role.START]
    }
  },
  [Ownerships.Byggfakta]: {
    domain: import.meta.env.VITE_PARTNER_BF_DOMAIN,
    qrCodeTitle: 'Byggfakta Tender',
    logo: ByggfaktaLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/byggfakta-icon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/byggfakta-icon.svg`
    },
    images: {
      buyerProfile: `${import.meta.env.BASE_URL}images/bf_hero_organisations.png`
    },
    links: {
      POLICY_URL: '#',
      LANDING_URL: 'https://www.byggfaktatender.com',
      CONTACT_URL: 'https://www.byggfaktatender.com/sv/inkopare',
      SUPPORT_EMAIL: 'tender@byggfakta.se'
    },
    oAuth: {
      redirectSignIn: [import.meta.env.VITE_PARTNER_BF_REDIRECT_URL],
      redirectSignOut: [import.meta.env.VITE_PARTNER_BF_REDIRECT_URL]
    },
    paymentRoles: {
      roles: BF_PARTNER_PAYMENT_ROLES,
      defaultRoles: [Role.BF_BASIC]
    }
  },
  [Ownerships.Clira]: {
    domain: import.meta.env.VITE_PARTNER_CLR_DOMAIN,
    logo: CliraLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/clira-icon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/clira-icon.svg`
    },
    links: {
      POLICY_URL: '',
      LANDING_URL: 'https://www.clira.io/',
      CONTACT_URL: 'https://www.clira.io/#komigang',
      SUPPORT_EMAIL: 'info@areachica.se'
    },
    oAuth: {
      redirectSignIn: [import.meta.env.VITE_PARTNER_CLR_REDIRECT_URL],
      redirectSignOut: [import.meta.env.VITE_PARTNER_CLR_REDIRECT_URL]
    },
    paymentRoles: {
      roles: CLR_PAYMENT_ROLES,
      defaultRoles: [Role.CLR_PROCURER]
    }
  }
};
