import React, { FC, useCallback, useRef, useState } from 'react';
import EditableBlockTextSimple from './EditableBlockSimple';
import ReadOnlyBlockText from './ReadOnlyBlock';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { useClickAway, useKey } from 'react-use';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';
import { EditableBlockText } from './EditableBlock';

export interface IProps extends IBlockProps<IBoxFieldString> {
  isSimple: boolean;
}
export const BlockText: FC<IProps> = ({ field, onChange, translation, viewMode, isEditable, isSimple, config }) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  const onFinish = useCallback(
    (val: IBoxFieldString) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  return (
    <>
      {currentMode === FieldViewMode.Edit &&
        (isSimple ? (
          <EditableBlockTextSimple containerRef={ref} onChange={onFinish} onFinish={switchToReadMode} field={field} />
        ) : (
          <EditableBlockText containerRef={ref} onChange={onFinish} onFinish={switchToReadMode} field={field} />
        ))}

      {currentMode === FieldViewMode.Read && (
        <>
          {!!field.string ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
              <ReadOnlyBlockText
                string={field.string}
                translation={translation}
                className={styles.block}
                config={config}
              />
              {isEditable && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addText')} />
          )}
        </>
      )}
    </>
  );
};

export default BlockText;
