import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useDeadlineDate } from 'src/helpers';

interface IProps {
  deadline: number | null;
}

export const Deadline: FC<IProps> = props => {
  const { deadline } = props;
  const { t } = useTranslation();
  const deadlineValue = useDeadlineDate(deadline);

  return (
    <dl className={styles.date}>
      <dt className={styles.name}>{t('Tenders.deadline')} :</dt>
      <dd className={classNames(styles.value, { [styles.isExpired]: deadlineValue.isExpired })}>
        {deadlineValue.value}
      </dd>
    </dl>
  );
};

export default Deadline;
