import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';

export interface IApiBaseStage {
  id: string;
  name: string;
}

export interface IBaseStage extends IApiBaseStage {
  color: string;
}
export interface IApiWorkspaceStatus extends IApiBaseStage {
  description: string | null;
  order: number;
  category: WorkspaceStatusCategory;
  rank: string;
}

export interface IWorkspaceStatus extends IApiWorkspaceStatus, IBaseStage {}

export const NO_STATUS_COLOR = '#909090';

export enum WorkspaceStatus {
  Qualify = 'Qualify',
  Create = 'Create',
  Review = 'Review',
  Submit = 'Submit',
  Evaluation = 'Evaluation',
  Awarded = 'Awarded',
  Lost = 'Lost',
  Rejected = 'Rejected'
}

export const DEFAULT_STAGE_COLORS: Record<string, string> = {
  Qualify: '#38A9BE',
  Create: '#CA8D1C',
  Review: '#8601E8',
  Submit: '#412C38',
  Evaluation: '#FDE360',
  Awarded: '#D13263',
  Lost: '#FA6400',
  Rejected: '#A3A3A3'
};

export interface IWorkspaceStatusToBe {
  status: IWorkspaceStatus;
  shouldUpdate?: boolean;
}
