import {
  faBoxOpenFull,
  faPeopleGroup,
  faCoin,
  faEye,
  faMedal,
  faClipboardCheck,
  faGear,
  faMemo,
  faCalendarClock,
  faSignature,
  faPlus,
  faMessagesQuestion,
  faAward,
  faFileCircleCheck,
  faAddressBook,
  faMap,
  faLink,
  faCircleExclamationCheck,
  faBrainCircuit,
  faPenField,
  faTimelineArrow,
  faFiles,
  faMemoCircleInfo,
  faSignsPost,
  faMemoCircleCheck,
  faCode,
  faUsdCircle,
  faMagnifyingGlass,
  faCirclePlus,
  faText,
  faHashtag,
  faCoins,
  faCalendarExclamation,
  faClock,
  faLinkSimple,
  faPen,
  faTasks
} from '@fortawesome/pro-light-svg-icons';
import {
  faMemoCircleInfo as faMemoCircleInfoSolid,
  faFiles as faFilesSolid,
  faBoxOpenFull as faBoxOpenFullSolid,
  faCircleExclamationCheck as faCircleExclamationCheckSolid,
  faBrainCircuit as faBrainCircuitSolid,
  faTimelineArrow as faTimelineArrowSolid,
  faPenField as faPenFieldSolid,
  faClipboardCheck as faClipboardCheckSolid,
  faFileCircleCheck as faFileCircleCheckSolid,
  faAward as faAwardSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faTasks as faTasksSolid
} from '@fortawesome/pro-solid-svg-icons';
import { TenderSubCatName, TenderCatName, ITenderDataKeys } from './types';
import { IIconMapper } from 'src/types/icon';
import { TenderBoxFieldType } from '@tendium/prom-types/tender';
import { BoxSpecId } from '../types';

export const CAT_ICON_MAPPER: IIconMapper = {
  SUPPLIER: { defaultIcon: faCircleExclamationCheck, activeIcon: faCircleExclamationCheckSolid },
  'PRODUCT/SERVICE': { defaultIcon: faBrainCircuit, activeIcon: faBrainCircuitSolid },
  BIDDING_PROCESS: { defaultIcon: faTimelineArrow, activeIcon: faTimelineArrowSolid },
  CONTRACT: { defaultIcon: faPenField, activeIcon: faPenFieldSolid },
  OTHER: { defaultIcon: faBoxOpenFull, activeIcon: faBoxOpenFullSolid },
  ADDITIONS_AND_QA: { defaultIcon: faClipboardCheck, activeIcon: faClipboardCheckSolid },
  AWARD: { defaultIcon: faAward, activeIcon: faAwardSolid },
  SIMILAR_TENDER: { defaultIcon: faFileCircleCheck, activeIcon: faFileCircleCheckSolid },
  OUTCOME: { defaultIcon: faAward, activeIcon: faAwardSolid },
  [TenderCatName.Overview]: { defaultIcon: faMemoCircleInfo, activeIcon: faMemoCircleInfoSolid },
  [TenderCatName.Docs]: { defaultIcon: faFiles, activeIcon: faFilesSolid },
  [TenderCatName.KeywordSearch]: { defaultIcon: faMagnifyingGlass, activeIcon: faMagnifyingGlassSolid },
  [TenderCatName.Bidding]: { defaultIcon: faTasks, activeIcon: faTasksSolid }
};

export const SUB_CAT_ICON_MAPPER: IIconMapper = {
  PERSONNEL: { defaultIcon: faPeopleGroup, color: '#2F54EB' },
  FINANCIALS: { defaultIcon: faCoin, color: '#2F54EB' },
  OVERVIEW: { defaultIcon: faEye, color: '#2F54EB' },
  EVALUATION_PROCESS: { defaultIcon: faMedal, color: '#2F54EB' },
  QUESTIONS_AND_ANSWERS: { defaultIcon: faMessagesQuestion, color: '#2F54EB' },
  REFERENCES: { defaultIcon: faClipboardCheck, color: '#2F54EB' },
  GENERAL_INFORMATION: { defaultIcon: faMemo, color: '#2F54EB' },
  TECHNICAL: { defaultIcon: faGear, color: '#2F54EB' },
  DATES_TIMELINE: { defaultIcon: faCalendarClock, color: '#2F54EB' },
  CONTRACT: { defaultIcon: faSignature, color: '#2F54EB' },
  CONTACT: { defaultIcon: faAddressBook, color: '#2F54EB' },
  PLACE_OF_PERFORMANCE: { defaultIcon: faMap, color: '#2F54EB' },
  LOCATION: { defaultIcon: faMap, color: '#2F54EB' },
  LINKS: { defaultIcon: faLink, color: '#2F54EB' },
  ADDITIONS: { defaultIcon: faPlus, color: '#2F54EB' },
  OUTCOME: { defaultIcon: faAward, color: '#2F54EB' },
  SIMILAR_TENDER: { defaultIcon: faFileCircleCheck, color: '#2F54EB' },
  FRAMEWORK_AGREEMENT: { defaultIcon: faBoxOpenFull, color: '#2F54EB' },
  OTHER: { defaultIcon: faBoxOpenFull, color: '#2F54EB' },
  [TenderSubCatName.Timeline]: { defaultIcon: faCalendarClock, color: '#5357EA' },
  [TenderSubCatName.Location]: { defaultIcon: faMap, color: '#5357EA' },
  [TenderSubCatName.ShortDescription]: { defaultIcon: faMemoCircleInfo, color: '#722ED1' },
  [TenderSubCatName.Intro]: { defaultIcon: faMemoCircleInfo, color: '#722ED1' },
  [TenderSubCatName.ScopeOfContract]: { defaultIcon: faMemoCircleInfo, color: '#722ED1' },
  [TenderSubCatName.Contact]: { defaultIcon: faAddressBook, color: '#FA8C16' },
  [TenderSubCatName.Contract]: { defaultIcon: faSignature, color: '#52C41A' },
  [TenderSubCatName.Procurement]: { defaultIcon: faMemoCircleCheck, color: '#13C2C2' },
  [TenderSubCatName.Additional]: { defaultIcon: faCirclePlus, color: '#2F54EB' },
  [TenderSubCatName.Cpv]: { defaultIcon: faCode, color: '#5357EA' },
  [TenderSubCatName.Competitors]: { defaultIcon: faUsdCircle, color: '#FA541C' },
  [TenderSubCatName.Lots]: { defaultIcon: faSignsPost, color: '#2F54EB' },
  [TenderSubCatName.Custom]: { defaultIcon: faPen, color: '#13C2C2' }
};

export const CATS_ORDER = [
  `GENERAL_INFORMATION`,
  `SUPPLIER`,
  `PRODUCT/SERVICE`,
  `BIDDING_PROCESS`,
  `CONTRACT`,
  `OTHER`,
  `ADDITIONS_AND_QA`,
  `AWARD`,
  `SIMILAR_TENDER`,
  `OUTCOME`,
  `DOCUMENTS`,
  `KEYWORD_SEARCH`
];

export const SUB_CATS_ORDER = [
  {
    id: 'GENERAL_INFORMATION',
    boxes: [
      'Introduction',
      'Short description',
      'Scope of contract',
      'Deadline for questions',
      'Submission deadline',
      'Evaluation model',
      'Published',
      'Link to submission',
      'Start and end date',
      'Estimated value',
      'Duration',
      'Contract renewal',
      'Suppliers assigned to agreement',
      'Contact',
      'Procuring agency',
      'Postal Address',
      'Organization number' // Used before translation
    ]
  },
  {
    id: 'GENERAL_INFORMATION.OTHER',
    boxes: [
      'Reference-ID',
      'Additional information',
      'Optional description',
      'Procurement timeline',
      'Description of tender',
      'Framework agreement with a single operator',
      'Justification for a long framework agreement'
    ]
  },
  {
    id: 'SUPPLIER.REFERENCES',
    boxes: [
      'Reference',
      'Reference timescale',
      'Reference work volume',
      'Buyer as reference',
      'Contract area',
      'Minimum economic size',
      'Minimum number of users',
      'Public Agent'
    ]
  },
  {
    id: 'SUPPLIER.FINANCIALS',
    boxes: [
      'Credit rating',
      'Revenue',
      'Equity ratio',
      'Gross profit',
      'Net profit',
      'Quick ratio',
      'Personnel cost',
      'Personnel cost/revenue'
    ]
  },
  {
    id: 'SUPPLIER.OTHER',
    boxes: [
      "Supplier's certificates",
      'Quality',
      'Environment',
      'Information security',
      'Insurance',
      'Company presentation'
    ]
  },
  {
    id: 'PRODUCT/SERVICE.GENERAL_INFORMATION',
    boxes: [
      'Current System(s)',
      'Estimated Number of users',
      'Service requirements',
      'Service-level agreement',
      'Support hours'
    ]
  },
  {
    id: 'PRODUCT/SERVICE.PERSONNEL',
    boxes: ['Competence levels', 'Role']
  },
  {
    id: 'PRODUCT/SERVICE.TECHNICAL',
    boxes: [
      'Apps',
      'Cloud or On-Prem service',
      'Data storage location',
      'Integration',
      'Language in system',
      'Offline solution'
    ]
  },
  {
    id: 'PRODUCT/SERVICE.OTHER',
    boxes: ['Assortment changes', 'Delivery time', 'Product requirements', 'System requirements']
  },
  {
    id: 'BIDDING_PROCESS.OVERVIEW',
    boxes: [
      'Electronic invoicing will be accepted',
      'Language on bid',
      'Tender submission details',
      'Description of bidding process'
    ]
  },
  {
    id: 'BIDDING_PROCESS.EVALUATION_PROCESS',
    boxes: ['Evaluation model', 'Award criteria', 'Bid pricing information']
  },
  {
    id: 'BIDDING_PROCESS.DATES_TIMELINE',
    boxes: ['Demonstration', 'Presentation', 'Showing of object(s)', 'Other date']
  },
  {
    id: 'BIDDING_PROCESS.OTHER',
    boxes: ['Appendices to include', 'Confidentiality', 'Consortia and prime subcontractors', 'Implementation plan']
  },
  {
    id: 'CONTRACT.CONTRACT',
    boxes: [
      'Price adjustment',
      'Framework agreement call offs',
      'Summary of deviations from general provisions',
      'Deviations from general provisions',
      'Damages',
      'Dispute',
      'Contract termination',
      'Intellectual property contract terms'
    ]
  },
  {
    id: 'AWARD.OUTCOME',
    boxes: [
      'Additional information on contract award',
      'Aggregate CAN source URL',
      'Bid statistics',
      'Bidder information',
      'Date of dispatch of this contract award notice',
      'Information on non-awarded contracts',
      'Link to the contract award notice'
    ]
  },
  { id: 'SIMILAR_TENDER.SIMILAR_TENDER', boxes: ['Similar tender'] }
];

export const ADDITION_INFO_ORDER = [
  'Short description',
  'description',
  'Introduction',
  'introduction',
  'Scope of contract',
  'scopeOfContract',
  'Place of performance',
  'locationBoxes',
  'Place of performance nuts code',
  'placeOfPerformanceNutsCode',
  'Deadline of clarification questions',
  'deadlineOfClarificationQuestions',
  'Submission deadline',
  'deadline',
  'Evaluation model',
  'awardCriteriaBoxes',
  'Available date',
  'availableDate',
  'Type of procedure',
  'typeOfProcedureBoxes',
  'Framework agreement',
  'frameworkAgreement',
  'Link for submitting tender',
  'linkForSubmittingTenderBoxes',
  'Estimated contract value',
  'contractValueBoxes',
  'Contract period',
  'Contract duration',
  'contractDurationBoxes',
  'Contract renewal',
  'contractRenewalBoxes',
  'Demonstration',
  'demonstrationStart',
  'demonstrationEnd',
  'The number of suppliers assigned to framework agreement',
  'numberOfSuppliersAssignedContract',
  'Bid validity period',
  'bidValidityBoxes',
  'Contact',
  'Contact person',
  'contactPersonBoxes',
  'Phone number',
  'phoneBoxes',
  'E-mail',
  'emailBoxes',
  'Postal address',
  'postalAddress',
  'Postal code',
  'postcode',
  'Town',
  'city',
  'Nuts code',
  'buyerNutsCode',
  'Procuring agency',
  'Procuring agency ID',
  'buyerBoxes'
];

export const BOOLEAN_ANSWERS = {
  positive: ['yes', 'ja'],
  negative: ['no', 'nej']
};

export const URL_MARKERS = ['http', 'www'];

export const EMAIL_BOX_SPEC_IDS = [BoxSpecId['E-MAIL'], BoxSpecId.EMAIL];

export const SUB_CATS_BOXES: Record<string, ITenderDataKeys[]> = {
  [TenderSubCatName.Intro]: ['introduction'],
  [TenderSubCatName.ShortDescription]: ['description'],
  [TenderSubCatName.ScopeOfContract]: ['scopeOfContract'],
  [TenderSubCatName.Timeline]: [
    'availableDate',
    'minimumTimeFrame',
    'contractStartAndEndStart',
    'contractStartAndEndEnd',
    'deadlineOfClarificationQuestions',
    'expectedPublicationDate',
    'deadline',
    'demonstrationStart',
    'demonstrationEnd',
    'presentationStart',
    'presentationEnd',
    'showingOfObjectsStart',
    'showingOfObjectsEnd',
    'dateOfDispatch'
  ],
  [TenderSubCatName.Location]: ['locationBoxes', 'placeOfPerformanceNutsCode'],
  [TenderSubCatName.Procurement]: [
    'typeOfProcedureBoxes',
    'deadline',
    'availableDate',
    'deadlineOfClarificationQuestions',
    'linkForSubmittingTenderBoxes',
    'linkToQA',
    'frameworkAgreement',
    'referenceNumberBoxes'
  ],
  [TenderSubCatName.Contact]: [
    'buyerBoxes',
    'phoneBoxes',
    'contactPersonBoxes',
    'emailBoxes',
    'city',
    'postalAddress',
    'buyerNutsCode',
    'postcode'
  ],
  [TenderSubCatName.Contract]: [
    'contractStartAndEndStart',
    'contractStartAndEndEnd',
    'contractValueBoxes',
    'contractDurationBoxes',
    'contractRenewalBoxes',
    'numberOfSuppliersAssignedContract'
  ],
  [TenderSubCatName.Cpv]: ['mainCpvCode', 'additionalCpvCode']
};

export const EXCLUDED_FROM_ADDITIONAL_KEYS: ITenderDataKeys[] = [
  ...SUB_CATS_BOXES[TenderSubCatName.Cpv],
  'contractDurationBoxes',
  'contractRenewalBoxes',
  'contractStartAndEndStart',
  'contractStartAndEndEnd'
];

export const TRUNC_CHARS_MAX = 60;

export const CUSTOM_FIELDS_TYPE_MAPPER: IIconMapper = {
  [TenderBoxFieldType.String]: { defaultIcon: faText, color: '#722ED1' },
  [TenderBoxFieldType.Number]: { defaultIcon: faHashtag, color: '#52C41A' },
  [TenderBoxFieldType.Money]: { defaultIcon: faCoins, color: '#FFC53D' },
  [TenderBoxFieldType.Date]: { defaultIcon: faCalendarExclamation, color: '#F5222D' },
  [TenderBoxFieldType.DateRange]: { defaultIcon: faClock, color: '#5357EA' },
  [TenderBoxFieldType.URL]: { defaultIcon: faLinkSimple, color: '#5357EA' }
};

export const CUSTOM_BID_FIELDS_ORDER = [TenderBoxFieldType.String];

export const FILE_CATEGORY_UNKNOWN = 'Unknown file category';
