import { useEffect } from 'react';
import { useFavicon, useTitle } from 'react-use';
import { groupByCompany, identifyUser } from './segment';
import { useApp, isPaidUser } from './models/auth';
import { ownership } from './models/ownership';

export function useInitHook(): void {
  const { user } = useApp();

  useTitle(ownership.metaTitle);
  useFavicon(ownership.favicon.ico);
  useFavicon(ownership.favicon.svg);

  document.body.setAttribute('data-theme', ownership.name);

  useEffect(() => {
    if (user) {
      identifyUser(user.email, {
        name: user.name ?? '',
        lastName: user.lastName ?? '',
        userType: user.permission
      });
      if (isPaidUser(user)) {
        groupByCompany(user.company.id, {
          name: user.company.companyName,
          plan: user.company.pricePlan
        });
      }
    }
  }, [user]);
}
