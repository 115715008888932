import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

export enum TagType {
  Beta = 'Beta',
  New = 'New',
  Light = 'Light'
}

interface Props {
  type: TagType;
  className?: string;
  withInfoIcon?: boolean;
}

export const Tag: FC<Props> = props => {
  const { type, className, withInfoIcon } = props;
  const { t } = useTranslation();

  const text =
    type === TagType.Beta ? t('Common.betaTag') : type === TagType.Light ? t('Common.lightTag') : t('Common.newTag');

  return (
    <span className={classNames(styles.tag, className)}>
      {text}
      {withInfoIcon ? <FontAwesomeIcon icon={faCircleInfo} className={styles.tagInfoIcon} /> : null}
    </span>
  );
};

export default Tag;
