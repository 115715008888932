import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { EmptyFilter, Spinner } from 'src/common';
import { ChartContainerProps } from '../types';
import styles from './index.module.scss';

export const ChartContainer: FC<ChartContainerProps> = ({ title, desc, error, loading, className, children }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {!!title && (
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {!!desc && <div className={styles.desc}>{desc}</div>}
        </div>
      )}
      {error || loading ? (
        <>
          {!!error && <EmptyFilter className={styles.errorMessage} title={''} desc={t('Common.Chart.errorDesc')} />}
          {!!loading && <Spinner className={styles.spinner} text={t('Common.Chart.loadingText')} isLarge />}
        </>
      ) : (
        <ParentSize className={styles.chartContainer}>{children}</ParentSize>
      )}
    </div>
  );
};

export default ChartContainer;
