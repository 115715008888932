import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MutationTuple,
  MutationUpdaterFn,
  QueryResult,
  useMutation,
  useQuery,
  Reference,
  ApolloCache,
  useLazyQuery,
  ApolloError,
  FetchResult,
  LazyQueryResultTuple
} from '@apollo/client';
import {
  BTag,
  IApiMp,
  IMatchingProfile,
  PREFIX_SEARCH_LIMIT,
  IMpSnapshot,
  IMpSnapshotValues,
  INIT_SNAPSHOT,
  CodeFilter,
  MpCode,
  MpCodeTypes,
  isCpvCodesResponse,
  CpvCodesResponse,
  NutsCodesResponse,
  MpContractValue,
  MpKeyword,
  MpKeywordGroup,
  UpdateMpVars,
  CreateMpVars
} from './types';
import {
  CREATE_MP,
  DELETE_MP,
  LOAD_ALL_MY_MP,
  LOAD_BTAGS,
  LOAD_MP,
  SEARCH_CPV_CODES,
  SEARCH_REGIONS,
  UPDATE_MP_BTAGS,
  UPDATE_MP_CPV_CODES,
  UPDATE_MP_NAME,
  UPDATE_MP_NUTS_CODES,
  UPDATE_MP_BUYERS,
  DELETE_MP_MULTIPLE_KEYWORDS,
  UPDATE_MP_MULTIPLE_KEYWORDS,
  CREATE_MULTIPLE_MP_KEYWORD,
  LOAD_ALL_MY_MP_IDS,
  LOAD_MP_KEYWORDS,
  UPDATE_MP_BUYER_TYPES,
  UPDATE_MP_CONTRACT_VALUE,
  GET_NUTS_FILTER,
  UPDATE_MP,
  CREATE_MP_MULTIPLE_KEYWORD_GROUPS,
  UPDATE_MP_KEYWORD_WIDTH,
  UPDATE_MP_RELEVANCE_DESCRIPTION
} from './queries';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { MatchingProfile } from '.';
import { ApiTendersData, ApiTendersVars } from '../procurements/Tenders/types';
import { GET_SIMPLE_TENDERS } from '../procurements/Tenders/queries';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { BuyerType, FilterLogic, KeywordWidth, ProfileType } from '@tendium/prom-types';
import { BaseKeywordDTO, KeywordDTO, KeywordGroupDTO } from '@tendium/prom-types/schema';
import { useParams } from 'react-router';
import { cache } from 'src/lib/API/graphql/cache';
import { useAllUsersAndTeams } from '../users/hooks';

export function clearTendersCacheOnMpUpdate(cache: ApolloCache<unknown>, mpId: string): void {
  const tendersQ: string[] = [];
  cache.modify({
    fields: {
      getSimpleTenders: (existing, { storeFieldName }) => {
        if (storeFieldName.includes(mpId)) {
          tendersQ.push(storeFieldName);
        }
        return existing;
      }
    }
  });
  !!tendersQ.length &&
    tendersQ.forEach(queryId => {
      cache.evict({
        fieldName: queryId
      });
    });
  cache.gc();
}

export function useLoadAllMps(skip?: boolean): Omit<QueryResult<{ getAllMyMatchingProfiles: IApiMp[] }>, 'data'> & {
  data?: IMatchingProfile[];
} {
  const allUsersAndTeams = useAllUsersAndTeams();
  const query = useQuery<{ getAllMyMatchingProfiles: IApiMp[] }>(LOAD_ALL_MY_MP, {
    skip
  });
  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? query.data.getAllMyMatchingProfiles.map(mp => new MatchingProfile(mp, allUsersAndTeams))
        : undefined
    }),
    [allUsersAndTeams, query]
  );
}

export type MpShort = Pick<MatchingProfile, 'id' | 'name' | 'profileType'>;
interface LoadMpIdsResponse {
  getAllMyMatchingProfiles: MpShort[];
}
export function useLoadAllMpsIds(skip?: boolean, profileType?: ProfileType): QueryResult<LoadMpIdsResponse> {
  return useQuery<LoadMpIdsResponse>(LOAD_ALL_MY_MP_IDS, {
    variables: profileType ? { input: { profileType } } : undefined,
    skip
  });
}

type ApiMpData = { getMatchingProfile: IApiMp };
type ApiMpVars = { id: string };
/**
 * @param {string} mpId - matching profile id, fallback is :mpId from url params
 * @param {boolean} skip - condition to skip load matching profile
 */
export function useLoadMp(
  mpId?: string,
  skip?: boolean
): Omit<QueryResult<ApiMpData, ApiMpVars>, 'data'> & { data?: IMatchingProfile } {
  const { mpId: activeMpId } = useParams<{ mpId: string }>();
  const allUsersAndTeams = useAllUsersAndTeams();
  const id = useMemo(() => mpId ?? activeMpId, [activeMpId, mpId]);
  const query = useQuery<ApiMpData, ApiMpVars>(LOAD_MP, {
    variables: { id: id ?? '' },
    skip: !id || skip
  });
  return useMemo(
    () => ({
      ...query,
      data: query.data ? new MatchingProfile(query.data.getMatchingProfile, allUsersAndTeams) : undefined
    }),
    [allUsersAndTeams, query]
  );
}
export function useLazyMp(): LazyQueryResultTuple<ApiMpData, ApiMpVars> {
  return useLazyQuery<ApiMpData, ApiMpVars>(LOAD_MP);
}

interface ILoadMpKeywordsResponse {
  id: string;
  keywords: MpKeyword[];
  name: string;
}

export function useMpKeywords(
  mpId?: string,
  skip?: boolean
): QueryResult<{ getMatchingProfile: ILoadMpKeywordsResponse }, { id?: string }> {
  return useQuery<{ getMatchingProfile: ILoadMpKeywordsResponse }, { id?: string }>(LOAD_MP_KEYWORDS, {
    variables: { id: mpId },
    skip: !mpId || skip
  });
}

export function useLoadBTags(): QueryResult<{ getBTags: BTag[] }> {
  return useQuery<{ getBTags: BTag[] }>(LOAD_BTAGS);
}

export function useLoadCpvCodes(): QueryResult<{
  searchCpvCodes: MpCode[];
}> {
  return useQuery<CpvCodesResponse>(SEARCH_CPV_CODES, {
    variables: { input: { query: '' } }
  });
}

export function useLoadNutsCodes(): QueryResult<{
  searchNutCodes: MpCode[];
}> {
  return useQuery<NutsCodesResponse>(SEARCH_REGIONS, {
    variables: { input: { query: '' } }
  });
}
export function useLoadMpCodes<Param extends MpCodeTypes>(
  type: Param
): { data?: MpCode[]; loading?: boolean; error?: ApolloError } {
  const query = useQuery<Param extends 'cpvCodes' ? CpvCodesResponse : NutsCodesResponse>(
    type === 'cpvCodes' ? SEARCH_CPV_CODES : SEARCH_REGIONS,
    {
      variables: { input: { query: '' } }
    }
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? isCpvCodesResponse(query.data)
          ? query.data.searchCpvCodes
          : query.data.searchNutCodes
        : undefined
    }),
    [query]
  );
}

export function useNutsFilter(skip?: boolean): QueryResult<{
  getNutsFilter: CodeFilter;
}> {
  return useQuery<{ getNutsFilter: CodeFilter }>(GET_NUTS_FILTER, {
    skip: !!skip
  });
}

export interface IUpdateMpResponse {
  __typename: 'Mutation';
  updateMatchingProfile: IApiMp & {
    __typename: 'MatchingProfile';
  };
}
export function useUpdateMp(): [(input: UpdateMpVars, onComplete?: () => void) => void, { loading: boolean }] {
  const { t } = useTranslation();
  const [updateMp, { loading }] = useMutation(UPDATE_MP);
  const updateMpFn = useCallback(
    (input: UpdateMpVars, onComplete?: () => void) => {
      const { id, ...values } = input;

      updateMp({
        variables: {
          id,
          ...values
        },
        update: getUpdateCacheOnUpdateMp(input)
      })
        .then(() => {
          onComplete && onComplete();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [t, updateMp]
  );
  return [updateMpFn, { loading }];
}

export function getUpdateCacheOnUpdateMp(input: UpdateMpVars): MutationUpdaterFn<IUpdateMpResponse> {
  const { id, ...values } = input;

  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpResponse = data.updateMatchingProfile;
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id
    });
    for (const field in values) {
      if (field !== undefined) {
        if (field === 'keywords') {
          cache.modify({
            id: mpRef,
            fields: {
              keywords(_, { toReference }) {
                return (
                  mpResponse[field]?.map(keyword => toReference({ __typename: 'KeywordDTO', id: keyword.id })) ?? null
                );
              }
            }
          });
        } else if (field === 'keywordGroups') {
          cache.modify({
            id: mpRef,
            fields: {
              keywordGroups(_, { toReference }) {
                return (
                  mpResponse[field]?.map(keyword =>
                    toReference({ __typename: 'KeywordGroupDTO', groupId: keyword.groupId })
                  ) ?? null
                );
              }
            }
          });
        } else if (field === 'supplierOrgIds' || field === 'supplierSearchStrings') {
          cache.modify({
            id,
            fields: {
              ['supplierSearchTerms'](_, { toReference }) {
                return mpResponse['supplierSearchTerms']?.map(term =>
                  toReference({ __typename: 'SupplierSearchTerm', id: term.id })
                );
              }
            }
          });
        } else if (field === 'contractValue') {
          cache.modify({
            id,
            fields: {
              ['contractValueMin']() {
                return mpResponse['contractValueMin'];
              },
              ['contractValueMax']() {
                return mpResponse['contractValueMax'];
              },
              ['contractValueCurrency']() {
                return mpResponse['contractValueCurrency'];
              }
            }
          });
        } else {
          cache.modify({
            id,
            fields: {
              [field]() {
                return mpResponse[field as keyof IApiMp];
              }
            }
          });
        }
      }
    }
    cache.gc();
  };
}

export interface ICreateKeywordGroupsResponse {
  __typename: 'Mutation';
  createMultipleKeywordGroups: KeywordGroupDTO[];
}

export interface ICreateKeywordGroupsPayload {
  matchingProfileId: string;
  values: string[][];
  filterLogic: FilterLogic;
}

export function useCreateMPKeywordGroups(): [
  (data: {
    matchingProfileId: string;
    values: string[][];
    filterLogic: FilterLogic;
  }) => Promise<void | FetchResult<ICreateKeywordGroupsResponse>>,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();

  const [createKeywordGroups, { loading, error }] = useMutation<
    ICreateKeywordGroupsResponse,
    ICreateKeywordGroupsPayload
  >(CREATE_MP_MULTIPLE_KEYWORD_GROUPS);
  const createKeywordGroupsFn = async (data: {
    matchingProfileId: string;
    values: string[][];
    filterLogic: FilterLogic;
  }): Promise<void | FetchResult<ICreateKeywordGroupsResponse>> => {
    const { matchingProfileId, values, filterLogic } = data;

    const result = await createKeywordGroups({
      variables: {
        matchingProfileId,
        values,
        filterLogic
      },
      update: getUpdateCacheOnCreateKeywordGroups(matchingProfileId)
    }).catch(() => {
      notification.error({
        description: t('Common.unknownErrorDesc'),
        message: t('Common.unknownError')
      });
    });

    return result;
  };

  return [createKeywordGroupsFn, { loading, error }];
}

export function getUpdateCacheOnCreateKeywordGroups(mpId: string): MutationUpdaterFn<ICreateKeywordGroupsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });

    const newKeywordRefs = data.createMultipleKeywordGroups.map(group => {
      return {
        __ref: cache.identify({
          __typename: 'KeywordGroupDTO',
          groupId: group.groupId
        })
      };
    });
    cache.modify({
      id: mpRef,
      fields: {
        keywordGroups(existingRefs: Reference[] = []) {
          return [...existingRefs, ...newKeywordRefs];
        }
      }
    });
  };
}
export interface ICreateKeywordResponse {
  __typename: 'Mutation';
  createMultipleKeywords: KeywordDTO[];
}

export interface ICreateKeywordPayload {
  matchingProfileId: string;
  values: string[];
  filterLogic: FilterLogic;
}

export function useCreateMPKeywords(): [
  (data: {
    matchingProfileId: string;
    values: string[];
    filterLogic: FilterLogic;
  }) => Promise<void | FetchResult<ICreateKeywordResponse>>,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();

  const [createKeyword, { loading, error }] = useMutation<ICreateKeywordResponse, ICreateKeywordPayload>(
    CREATE_MULTIPLE_MP_KEYWORD
  );
  const createKeyWordFn = async (data: {
    matchingProfileId: string;
    values: string[];
    filterLogic: FilterLogic;
  }): Promise<void | FetchResult<ICreateKeywordResponse>> => {
    const { matchingProfileId, values, filterLogic } = data;

    const result = await createKeyword({
      variables: {
        matchingProfileId,
        values,
        filterLogic
      },
      update: getUpdateCacheOnCreateKeywords(matchingProfileId)
    }).catch(() => {
      notification.error({
        description: t('Common.unknownErrorDesc'),
        message: t('Common.unknownError')
      });
    });

    return result;
  };

  return [createKeyWordFn, { loading, error }];
}

export function getUpdateCacheOnCreateKeywords(mpId: string): MutationUpdaterFn<ICreateKeywordResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });

    const newKeywordRefs = data.createMultipleKeywords.map(keyword => {
      return {
        __ref: cache.identify({
          __typename: 'KeywordDTO',
          id: keyword.id
        })
      };
    });
    cache.modify({
      id: mpRef,
      fields: {
        keywords(existingRefs: Reference[] = []) {
          return [...existingRefs, ...newKeywordRefs];
        }
      }
    });
  };
}

export interface IUpdateKeywordsResponse {
  __typename: 'Mutation';
  updateMultipleKeywords: {
    keywords: (MpKeyword & {
      __typename: 'KeywordDTO';
    })[];
    keywordGroups: (MpKeywordGroup & {
      __typename: 'KeywordGroupDTO';
    })[];
  };
}

export interface IUpdateKeywordsPayload extends BaseKeywordDTO {
  keywordIds: string[];
  keywordGroupIds: string[];
  matchingProfileId: string;
}

export function useUpdateMPKeywords(): [
  (matchingProfileId: string, keywordIds: string[], keywordGroupIds: string[], fieldToUpdate: BaseKeywordDTO) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();
  const [updateKeywords, { loading, error }] = useMutation<IUpdateKeywordsResponse, IUpdateKeywordsPayload>(
    UPDATE_MP_MULTIPLE_KEYWORDS
  );
  const updateKeywordFn = useCallback(
    (matchingProfileId: string, keywordIds: string[], keywordGroupIds: string[], fieldToUpdate: BaseKeywordDTO) => {
      updateKeywords({
        variables: { ...fieldToUpdate, keywordIds, keywordGroupIds, matchingProfileId },
        update: getUpdateCacheOnUpdateKeywords(fieldToUpdate)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [updateKeywords, t]
  );
  return useMemo(() => [updateKeywordFn, { loading, error }], [error, loading, updateKeywordFn]);
}

export function getUpdateCacheOnUpdateKeywords(
  fieldToUpdate: BaseKeywordDTO
): MutationUpdaterFn<IUpdateKeywordsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    if (data.updateMultipleKeywords.keywords.length) {
      for (const field in fieldToUpdate) {
        data.updateMultipleKeywords.keywords.forEach(keyword => {
          const keywordRef = cache.identify({
            __typename: 'KeywordDTO',
            id: keyword.id
          });

          cache.modify({
            id: keywordRef,
            fields: {
              [field]() {
                return keyword[field as keyof typeof fieldToUpdate];
              }
            }
          });
        });
      }
    }

    if (data.updateMultipleKeywords.keywordGroups.length) {
      for (const field in fieldToUpdate) {
        data.updateMultipleKeywords.keywordGroups.forEach(keyword => {
          const keywordRef = cache.identify({
            __typename: 'KeywordGroupDTO',
            groupId: keyword.groupId
          });

          cache.modify({
            id: keywordRef,
            fields: {
              [field]() {
                return keyword[field as keyof typeof fieldToUpdate];
              }
            }
          });
        });
      }
    }
  };
}

export interface IDeleteMPKeywordsResponse {
  deleteMultipleKeywords: {
    keywordIds: string[];
    keywordGroupIds: string[];
  };
  __typename: 'Mutation';
}

export interface IDeleteMPKeywordsPayload extends BaseKeywordDTO {
  keywordIds: string[];
  keywordGroupIds: string[];
  matchingProfileId: string;
}

export function useDeleteMPKeywords(): [
  (keywordIds: string[], keywordGroupIds: string[], matchingProfileId: string) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();
  const [deleteKeywords, { loading, error }] = useMutation<IDeleteMPKeywordsResponse, IDeleteMPKeywordsPayload>(
    DELETE_MP_MULTIPLE_KEYWORDS
  );

  const deleteKeywordsFn = useCallback(
    (keywordIds: string[], keywordGroupIds: string[], matchingProfileId: string) => {
      deleteKeywords({
        variables: { keywordIds, keywordGroupIds, matchingProfileId },
        update: getUpdateCacheOnDeleteKeywords(matchingProfileId, keywordIds, keywordGroupIds),
        optimisticResponse: {
          __typename: 'Mutation',
          deleteMultipleKeywords: {
            keywordIds,
            keywordGroupIds
          }
        }
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteKeywords, t]
  );
  return [deleteKeywordsFn, { loading, error }];
}

export function getUpdateCacheOnDeleteKeywords(
  mpId: string,
  keywordIds: string[],
  keywordGroupIds: string[]
): MutationUpdaterFn<{
  deleteMultipleKeywords: {
    keywordIds: string[];
    keywordGroupIds: string[];
  };
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });

    if (keywordIds.length) {
      keywordIds.forEach(id => {
        cache.modify({
          id: mpRef,
          fields: {
            keywords(existingRefs: Reference[], { readField }) {
              return existingRefs.filter(ref => readField('id', ref) !== id);
            }
          }
        });

        cache.evict({
          id: cache.identify({
            __typename: 'KeywordDTO',
            id
          })
        });
      });
    }

    if (keywordGroupIds.length) {
      keywordGroupIds.forEach(id => {
        cache.modify({
          id: mpRef,
          fields: {
            keywordGroups(existingRefs: Reference[], { readField }) {
              return existingRefs.filter(ref => readField('groupId', ref) !== id);
            }
          }
        });

        cache.evict({
          id: cache.identify({
            __typename: 'KeywordGroupDTO',
            groupId: id
          })
        });
      });
    }

    cache.gc();
  };
}

export function useUpdateMpCompaniesIds(): [
  (companiesIds: string[], searchStrings: string[], onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();
  const { data: mp } = useLoadMp(activeMpId);

  const [updateMpCompaniesIds, { loading, error }] = useMutation(UPDATE_MP_BUYERS);
  const updateMpCompaniesIdsFn = useCallback(
    (companiesIds: string[], searchStrings: string[], onComplete?: () => void) => {
      mp &&
        activeMpId &&
        updateMpCompaniesIds({
          variables: {
            id: activeMpId,
            buyerOrgIds: companiesIds,
            buyerSearchStrings: searchStrings
          },
          update: getUpdateCacheOnChangeCompaniesIds(activeMpId),
          optimisticResponse: {
            __typename: 'Mutation',
            updateMatchingProfile: {
              __typename: 'MatchingProfile',
              ...mp,
              buyerOrgIds: companiesIds,
              buyerSearchStrings: searchStrings
            }
          }
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeMpId, mp, t, updateMpCompaniesIds]
  );
  return [updateMpCompaniesIdsFn, { loading, error }];
}
export function useUpdateMpContractValue(): [
  (data: MpContractValue, onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();

  const [updateMpContractValue, { loading, error }] = useMutation(UPDATE_MP_CONTRACT_VALUE);

  const updateMpContractValueFn = useCallback(
    (data: MpContractValue, onComplete?: () => void) => {
      const { contractValueExcludeMissingValue, ...contractValueRange } = data;
      activeMpId &&
        updateMpContractValue({
          variables: { id: activeMpId, contractValueRange, contractValueExcludeMissingValue }
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeMpId, t, updateMpContractValue]
  );
  return [updateMpContractValueFn, { loading, error }];
}

export function useUpdateMpBuyerTypes(): [
  (buyerTypes: BuyerType[], onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();

  const [updateMpBuyerTypes, { loading, error }] = useMutation(UPDATE_MP_BUYER_TYPES);

  const updateMpMpBuyerTypesFn = useCallback(
    (buyerTypes: BuyerType[], onComplete?: () => void) => {
      activeMpId &&
        updateMpBuyerTypes({
          variables: { id: activeMpId, buyerTypes }
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeMpId, t, updateMpBuyerTypes]
  );
  return [updateMpMpBuyerTypesFn, { loading, error }];
}

export function getUpdateCacheOnChangeCompaniesIds(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        buyerOrgIds() {
          return data.updateMatchingProfile.buyerOrgIds;
        },
        buyerSearchStrings() {
          return data.updateMatchingProfile.buyerSearchStrings;
        }
      }
    });
  };
}

export function useUpdateMpBTags(): MutationTuple<IUpdateMpResponse, { id: string; bTagIds: string[] }> {
  return useMutation(UPDATE_MP_BTAGS);
}
export function getUpdateCacheOnUpdateMpBTags(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        bTagIds() {
          return data.updateMatchingProfile.bTagIds;
        }
      }
    });
  };
}

export function useUpdateMpKeywordWidth(): [
  (keywordWidth: KeywordWidth, mp: IMatchingProfile) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();

  const [updateMpKeywordWidth, { loading, error }] = useMutation<
    IUpdateMpResponse,
    { keywordWidth: KeywordWidth; id: string }
  >(UPDATE_MP_KEYWORD_WIDTH);

  const updateMpKeywordWidthFn = useCallback(
    (keywordWidth: KeywordWidth, mp: IMatchingProfile) => {
      updateMpKeywordWidth({
        variables: {
          id: mp.id,
          keywordWidth
        },
        update: getUpdateCacheOnUpdateMpKeywordWidth(mp.id),
        optimisticResponse: {
          __typename: 'Mutation',
          updateMatchingProfile: {
            __typename: 'MatchingProfile',
            ...mp,
            keywordWidth
          }
        }
      }).catch(() => {
        notification.error({
          message: t('Common.unknownError'),
          description: t('Common.unknownErrorDesc')
        });
      });
    },
    [t, updateMpKeywordWidth]
  );
  return [updateMpKeywordWidthFn, { loading, error }];
}
export function getUpdateCacheOnUpdateMpKeywordWidth(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        keywordWidth() {
          return data.updateMatchingProfile.keywordWidth;
        }
      }
    });
  };
}

export function useUpdateMpCpvCodes(): MutationTuple<
  IUpdateMpResponse,
  { id: string; cpvCodes: string[]; cpvCodesExact: string[] }
> {
  return useMutation(UPDATE_MP_CPV_CODES);
}
export function getUpdateCacheOnUpdateMpCpvCodes(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        cpvCodes() {
          return data.updateMatchingProfile.cpvCodes;
        },
        cpvCodesExact() {
          return data.updateMatchingProfile.cpvCodesExact;
        }
      }
    });
  };
}

export function useUpdateMpNutsCodes(): MutationTuple<
  IUpdateMpResponse,
  { id: string; nutsCodes: string[]; nutsCodesExact: string[] }
> {
  return useMutation(UPDATE_MP_NUTS_CODES);
}

export function getUpdateCacheOnUpdateMpNutsCodes(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        nutsCodes() {
          return data.updateMatchingProfile.nutsCodes;
        },
        nutsCodesExact() {
          return data.updateMatchingProfile.nutsCodesExact;
        }
      }
    });
  };
}

export function useUpdateMpRelevanceDescription(): [
  (relevanceDescription: string, onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();

  const [updateMpRelevanceDescription, { loading, error }] = useMutation(UPDATE_MP_RELEVANCE_DESCRIPTION);

  const updateMpRelevanceDescriptionFn = useCallback(
    (relevanceDescription: string, onComplete?: () => void) => {
      activeMpId &&
        updateMpRelevanceDescription({
          variables: { id: activeMpId, relevanceDescription }
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeMpId, t, updateMpRelevanceDescription]
  );
  return [updateMpRelevanceDescriptionFn, { loading, error }];
}

export function useUpdateMpName(): MutationTuple<IUpdateMpResponse, { id: string; name: string }> {
  return useMutation(UPDATE_MP_NAME);
}
export function getUpdateCacheOnUpdateMpName(mpId: string): MutationUpdaterFn<IUpdateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const mpRef = cache.identify({
      __typename: 'MatchingProfile',
      id: mpId
    });
    cache.modify({
      id: mpRef,
      fields: {
        name() {
          return data.updateMatchingProfile.name;
        }
      }
    });
  };
}

export interface ICreateMpResponse {
  __typename: 'Mutation';
  createMatchingProfile: IApiMp & {
    __typename: 'MatchingProfile';
  };
}

export function useCreateMp(): MutationTuple<ICreateMpResponse, CreateMpVars> {
  return useMutation(CREATE_MP);
}

export function getUpdateCacheOnCreateMp(isExpiringProfile?: boolean): MutationUpdaterFn<ICreateMpResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const newMpRef = cache.identify({ __typename: 'MatchingProfile', id: data.createMatchingProfile.id });
    newMpRef &&
      cache.modify({
        fields: {
          getAllMyMatchingProfiles: (existing: Reference[], { storeFieldName, toReference }) => {
            const includesExpProfile = storeFieldName.includes(ProfileType.ExpiringContract);
            if (isExpiringProfile ? includesExpProfile : !includesExpProfile) {
              return [...existing, toReference(newMpRef)];
            }
            return existing;
          }
        }
      });
    cache.modify({
      fields: {
        getCompanyMatchingProfilesCount: (existing: number) => {
          return existing + 1;
        }
      }
    });
  };
}

export function useDeleteMp(): MutationTuple<
  { deleteMatchingProfile: boolean; __typename: 'Mutation' },
  { id: string }
> {
  return useMutation(DELETE_MP);
}
export function getUpdateCacheOnDeleteMp(
  id: string,
  isExpiringProfile?: boolean
): MutationUpdaterFn<{
  deleteMatchingProfile: boolean;
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    cache.modify({
      fields: {
        getAllMyMatchingProfiles: (existing: Reference[], { storeFieldName, readField }) => {
          const includesExpProfile = storeFieldName.includes(ProfileType.ExpiringContract);
          if (isExpiringProfile ? includesExpProfile : !includesExpProfile) {
            const newRefs = [...existing].filter(mp => readField({ fieldName: id, from: mp }) !== id);
            if (!!newRefs.length) {
              return newRefs;
            } else {
              return;
            }
          }
          return existing;
        }
      }
    });
    cache.modify({
      fields: {
        getCompanyMatchingProfilesCount: (existing: number) => {
          return existing - 1;
        }
      }
    });

    cache.evict({ id: cache.identify({ id, __typename: 'MatchingProfile' }) });
    cache.gc();
    !isExpiringProfile && clearTendersCacheOnMpUpdate(cache, id);
  };
}

export function useMpSnapshot(): [boolean, (variables?: ApiTendersVars) => void] {
  const { data: mp } = useLoadMp();
  const [refetch] = useLazyQuery<ApiTendersData, ApiTendersVars>(GET_SIMPLE_TENDERS);
  const mpValues: IMpSnapshotValues | undefined = useMemo(
    () =>
      mp
        ? {
            id: mp.id,
            nutsCodes: mp.nutsCodes,
            nutsCodesExact: mp.nutsCodesExact,
            cpvCodes: mp.cpvCodes,
            cpvCodesExact: mp.cpvCodesExact,
            bTagIds: mp.bTagIds,
            keywordWidth: mp.keywordWidth,
            keywordGroups: mp.apiKeywordGroups,
            keywords: mp.apiKeywords,
            buyerOrgIds: mp.buyerOrgIds,
            buyerSearchStrings: mp.buyerSearchStrings,
            supplierSearchTerms: mp.supplierSearchTerms,
            supplierSearchLogic: mp.supplierSearchLogic,
            contractValueCurrency: mp.contractValueCurrency,
            contractValueMax: mp.contractValueMax,
            contractValueMin: mp.contractValueMin,
            contractValueExcludeMissingValue: mp.contractValueExcludeMissingValue,
            buyerTypes: mp.buyerTypes,
            relevanceDescription: mp.matchingProfileEmbedding
          }
        : undefined,
    [mp]
  );

  const [snapshot, setSnapshot] = useState<IMpSnapshot>(INIT_SNAPSHOT);

  useEffect(() => {
    setSnapshot(currentState => {
      const isProfileChanged =
        currentState.initial === undefined || (mpValues && currentState.initial.id !== mpValues.id);
      if (isProfileChanged) {
        return {
          ...currentState,
          initial: mpValues,
          isUpdated: false
        };
      }
      return {
        ...currentState,
        isUpdated: !isEqual(currentState.initial, mpValues)
      };
    });
  }, [mpValues]);
  const reset = useCallback(
    (variables?: ApiTendersVars) => {
      setSnapshot(currentState => ({
        ...currentState,
        initial: mpValues,
        isUpdated: !currentState.isUpdated
      }));
      const tendersQ: string[] = [];
      cache.modify({
        fields: {
          getSimpleTenders: (existing, { storeFieldName }) => {
            if (snapshot.initial?.id && storeFieldName.includes(snapshot.initial.id)) {
              tendersQ.push(storeFieldName);
            }
            return existing;
          }
        }
      });
      if (tendersQ.length) {
        tendersQ.forEach(queryId => {
          cache.evict({
            fieldName: queryId
          });
        });
        cache.gc();
      } else {
        refetch({ variables });
      }
    },
    [mpValues, refetch, snapshot.initial]
  );

  return useMemo(() => {
    return [snapshot.isUpdated, reset];
  }, [reset, snapshot]);
}

export function usePrefixSearchLimit(): number {
  const isSearchLimit50 = useFeatureFlag(FeatureFlag.SearchLimit50);
  return useMemo(() => (isSearchLimit50 ? 50 : PREFIX_SEARCH_LIMIT), [isSearchLimit50]);
}
