import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { ColumnType, SorterResult, SortOrder, TableRowSelection } from 'antd/lib/table/interface';
import { GetComponentProps } from 'rc-table/lib/interface';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { EmptyFilter, Skeleton, Table, TenderStatusBadge, Tooltip, useExistingTranslation } from 'src/common';
import { ActivePreviewTab } from 'src/models/procurements/types';
import styles from './index.module.scss';
import Published from 'src/pages/TendersPage/TendersList/Published';
import Deadline from 'src/pages/TendersPage/TendersList/Deadline';
import RowComponent from './Dnd';
import classNames from 'classnames';
import WorkspaceActionsMultiple from './ActionsMultiple';
import { BlockEmpty, MultiBuyerBoxes, LoadMoreButton, WorkspaceStatusPicker } from 'src/shared';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { useCurrentSidebar, useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/pro-light-svg-icons';
import { BidsColumn, IBids, IBidSorting, isBidSortField, SortOrder as BidSortOrder } from 'src/models/bids/Bids/types';
import { isBidspaceFiltersApplied, useBidspacesQpVars, useStagesBids } from 'src/models/bids/Bids/hooks';
import useSelectedBids from 'src/reactiveVars/SelectedBidsVar';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { useBoolean, useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import BoxField from 'src/shared/Tender/Boxes/BoxField';
import { useAllCustomBidFields } from 'src/models/company/CustomBidFields/hooks';
import { ICustomBidField } from 'src/models/company/CustomBidFields/types';
import BaseBadge from 'src/common/BaseBadge';
import { useFetchMore } from 'src/common/infinityScroll';
import { Key } from 'antd/es/table/interface';
import { trackOpenBidPreviewInWorkspace, trackWorkspaceTableSorting } from 'src/segment/events';
import BidWebhooksButton from 'src/shared/Bid/BidWebhooksButton';
import { IApiWebhook } from 'src/models/company/Webhooks/types';
import { useLoadWebhooks } from 'src/models/company/Webhooks/hooks';
import Assignee from 'src/shared/Assignee';
import { useParams } from 'react-router';
import { isStrings, isNotUndefined } from 'src/helpers';
import { useBidPreviewNav } from 'src/models/bids/BidPreview/hooks';

function mapSortingToSortOrder(column: BidsColumn, sorting?: IBidSorting): SortOrder {
  if (!sorting) {
    return null;
  }
  if (sorting.name === column && isBidSortField(column)) {
    switch (sorting.order) {
      case BidSortOrder.ASC: {
        return 'ascend';
      }
      case BidSortOrder.DESC: {
        return 'descend';
      }
    }
  }
  return null;
}

function useTableColumns(
  onGoToPreview: (procurementId: string, tab: ActivePreviewTab, workspaceId: string, categoryName?: string) => void,
  isWideScreen: boolean,
  customBoxes: ICustomBidField[],
  sorting?: IBidSorting,
  webhooks?: IApiWebhook[],
  showBidspace?: boolean
): ColumnProps<IBids>[] {
  const { existingT: t } = useExistingTranslation();

  return useMemo(() => {
    const columns = [
      {
        title: <WorkspaceActionsMultiple />,
        dataIndex: 'actions',
        key: BidsColumn.actions,
        className: styles.colActions,
        onCell: () => ({
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
          }
        })
      },
      {
        title: t('Common.status'),
        key: BidsColumn.status,
        dataIndex: 'workspaceStatus',
        className: styles.colStatus,
        render: (_, record) => {
          return (
            <Tooltip title={t('BidSpaces.changeStatus')}>
              <WorkspaceStatusPicker bidId={record.id} colorful eventSource="Bidspace table view" />
            </Tooltip>
          );
        },
        onCell: () => ({
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
          }
        })
        // sortOrder: mapSortingToSortOrder(BidsColumn.status, sorting),
        // sorter: !!isSortByStatusFeature,
        // sortDirections: ['descend', 'ascend']
      },
      {
        title: t('BidSpaces.Filters.bidspace'),
        key: BidsColumn.bidspace,
        dataIndex: 'workspaceName',
        className: styles.colBuyer,
        ellipsis: true,
        render: (_, record) => <span>{record.workspace.name}</span>
      },
      {
        title: t('Tenders.published'),
        dataIndex: 'availableDate',
        key: BidsColumn.published,
        render: (_, record) => <Published published={record.origin.published} />,
        className: styles.colDate,
        sortOrder: mapSortingToSortOrder(BidsColumn.published, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: t('Common.title'),
        key: BidsColumn.title,
        dataIndex: 'name',
        className: styles.colName,
        ellipsis: true,
        sortOrder: mapSortingToSortOrder(BidsColumn.title, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (_, record) => (
          <Tooltip title={record.origin.name}>
            <span className={styles.name}>
              {record.isRejected && <BaseBadge title={t('BidSpaces.rejected')} theme={'rejected'} />}
              {record.origin.procurementStatus && (
                <TenderStatusBadge className={styles.tenderStatusBadge} statuses={record.origin.procurementStatus} />
              )}
              <span className={styles.nameLabel}>{record.origin.name}</span>
            </span>
          </Tooltip>
        )
      },
      {
        title: t('Tenders.buyer'),
        key: BidsColumn.buyer,
        dataIndex: 'buyerBoxes',
        className: styles.colBuyer,
        ellipsis: true,
        render: (_, record) => <MultiBuyerBoxes buyerBoxes={record.origin.buyerBoxes} noLink />,
        sortOrder: mapSortingToSortOrder(BidsColumn.buyer, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: t('Tenders.deadline'),
        key: BidsColumn.deadline,
        dataIndex: 'deadline',
        render: (_, record) => <Deadline deadline={record.origin.deadline} />,
        sortOrder: mapSortingToSortOrder(BidsColumn.deadline, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        className: styles.colDate
      },
      {
        title: t(isWideScreen ? 'Tenders.contractStart' : 'Tenders.contractStartShort'),
        dataIndex: 'contractStart',
        key: BidsColumn.contractStart,
        render: (_, record) => <Published published={record.origin.contractStart} />,
        className: styles.colDate,
        sorter: true,
        sortOrder: mapSortingToSortOrder(BidsColumn.contractStart, sorting),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: t(isWideScreen ? 'Tenders.contractEnd' : 'Tenders.contractEndShort'),
        dataIndex: 'contractEnd',
        key: BidsColumn.contractEnd,
        render: (_, record) => <Published published={record.origin.contractEnd} />,
        className: styles.colDate,
        sortOrder: mapSortingToSortOrder(BidsColumn.contractEnd, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: t(isWideScreen ? 'Tenders.frameworkAgreement' : 'Tenders.frameworkAgreementShort'),
        dataIndex: 'frameworkAgreement',
        key: BidsColumn.frameworkAgreement,
        render: (_, record) => (
          <span>{record.origin.frameworkAgreement ? t(`Tenders.${record.origin.frameworkAgreement}`) : '—'}</span>
        ),
        className: styles.colAgreement,
        sortOrder: mapSortingToSortOrder(BidsColumn.frameworkAgreement, sorting),
        sorter: true,
        sortDirections: ['descend', 'ascend']
      },
      ...customBoxes.map(custom => {
        const title = custom.name || '—';
        return {
          title: <span title={title}>{title}</span>,
          key: custom.id,
          dataIndex: 'customBoxes',
          className: styles.customCol,
          ellipsis: true,
          render: (_, record) => {
            const box = record.origin.customBoxes.find(bx => bx.id.includes(custom.id));
            return (
              <>
                {box ? (
                  <BoxField config={{ isTooltip: true }} isEditable={false} field={box.rawFields[0]} box={box} />
                ) : (
                  <BlockEmpty />
                )}
              </>
            );
          }
        } as ColumnProps<IBids>;
      }),
      {
        title: '',
        key: BidsColumn.infoActions,
        dataIndex: 'infoActions',
        className: styles.colInfoActions,
        onCell: () => ({
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
          }
        }),
        render: (_, record) => {
          const { assignedTo, isCommented } = record;
          return (
            <>
              <ul className={styles.infoActions}>
                <li className={classNames(styles.infoAction, { [styles.isHidden]: !assignedTo })}>
                  {assignedTo && <Assignee assignedToId={assignedTo} mode={'onlyAvatar'} showTooltip size={'xs'} />}
                </li>
                {webhooks &&
                  webhooks.map(webhook => (
                    <li className={styles.infoAction} key={webhook.id}>
                      <BidWebhooksButton
                        webhook={webhook}
                        bidId={record.id}
                        key={webhook.id}
                        disabled={true}
                        webhookStatus={record.webhookStatus}
                      />
                    </li>
                  ))}
                <li
                  className={classNames(styles.infoAction, styles.comment, {
                    [styles.isHidden]: !isCommented
                  })}
                >
                  <button
                    className={styles.commentsButton}
                    onClick={() =>
                      onGoToPreview(record.id, ActivePreviewTab.Comments, record.workspace.id, record.status?.category)
                    }
                  >
                    <FontAwesomeIcon icon={faCommentDots} />
                  </button>
                </li>
              </ul>
            </>
          );
        }
      }
    ] as ColumnProps<IBids>[];

    return columns.filter(col => (col.dataIndex === 'workspaceName' ? showBidspace : true));
  }, [t, sorting, isWideScreen, customBoxes, webhooks, onGoToPreview, showBidspace]);
}

const components = {
  body: {
    row: RowComponent
  }
};

export const BidsTable: FC<{ showBidspace?: boolean }> = ({ showBidspace }) => {
  const { t } = useTranslation();

  const [selectedBids, setSelectedBids] = useSelectedBids();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { data: bidsData, loading, error, fetchMore } = useStagesBids({ amount: 30 });
  const { wsId } = useParams<{ wsId: string }>();
  const [fetchMoreLoading, setFetchMoreLoading] = useBoolean(false);
  const vars = useBidspacesQpVars();

  const { data: webhooks } = useLoadWebhooks();

  const loadMore = useCallback(
    (variables: { offset: number }) => {
      setFetchMoreLoading(true);
      return fetchMore({
        variables
      }).finally(() => setFetchMoreLoading(false));
    },
    [fetchMore, setFetchMoreLoading]
  );
  const onLoadMore = useFetchMore({ offset: bidsData?.bids.length || 0 }, loadMore);
  const bids = useMemo(() => bidsData?.bids.filter(isNotUndefined) || [], [bidsData]);

  const total = useMemo(() => bidsData?.total || 0, [bidsData]);

  const hasMore = useMemo(() => {
    return bids.length < total;
  }, [bids.length, total]);

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const isCustomBidFieldsFeature = useFeatureFlag(FeatureFlag.CustomBidFields);

  const isWideScreen = useMemo(() => {
    return width > 1440;
  }, [width]);

  const [searchVars, updateSearchArgs] = useBidsSearchArgs();

  const { data: customBoxesData, loading: isLoading } = useAllCustomBidFields();

  const customBoxes: ICustomBidField[] = useMemo(
    () => (isCustomBidFieldsFeature ? customBoxesData?.getAllCustomBidFields || [] : []),
    [customBoxesData, isCustomBidFieldsFeature]
  );

  const isEmptySearch = useMemo(() => {
    return !!bidsData && !bidsData.bids.length && isBidspaceFiltersApplied(vars);
  }, [bidsData, vars]);

  const isEmptyTable = useMemo(() => {
    return !!bidsData && !bidsData.bids.length && !isBidspaceFiltersApplied(vars);
  }, [bidsData, vars]);

  const openProcPreview = useOpenSidebar();
  const currentSidebar = useCurrentSidebar();
  const [, setPreviewTab] = usePreviewTabState();

  const eventSource = useBidPreviewNav();

  const onGoToPreview = useCallback(
    (procurementId: string, tab: ActivePreviewTab, workspaceId: string, categoryName?: string) => {
      openProcPreview({
        id: procurementId,
        mode: SidebarMode.BID_INFO
      });
      setPreviewTab(tab);
      trackOpenBidPreviewInWorkspace(
        {
          id: workspaceId
        },
        eventSource,
        categoryName
      );
    },
    [eventSource, openProcPreview, setPreviewTab]
  );

  const columns = useTableColumns(
    onGoToPreview,
    isWideScreen,
    customBoxes,
    searchVars.sortOnField,
    webhooks,
    showBidspace
  );

  const onSelectChange = useCallback(
    (selectedKeys: Key[]) => {
      if (selectedKeys.length > 0 && isStrings(selectedKeys)) {
        setSelectedBids(bids.filter(bid => selectedKeys.some((key: string) => key === bid.id)));
        return;
      }
      setSelectedBids([]);
    },
    [bids, setSelectedBids]
  );

  const rowSelectionConfig: TableRowSelection<IBids> = {
    onChange: onSelectChange,
    preserveSelectedRowKeys: false,
    selectedRowKeys: selectedBids.map(bid => bid.id),
    renderCell: (_, record, __, originNode) => <div className={styles.colActions}>{originNode}</div>
  };

  const onRow: GetComponentProps<IBids> = useCallback(
    record => {
      return {
        id: record.id,
        record,
        tabIndex: 0,
        onClick: () => onGoToPreview(record.id, ActivePreviewTab.Info, record.workspace.id, record.status?.category),
        onKeyPress: () => onGoToPreview(record.id, ActivePreviewTab.Info, record.workspace.id, record.status?.category)
      };
    },
    [onGoToPreview]
  );

  const selectionIsActive = selectedBids.length > 0;
  const onHeaderRow: GetComponentProps<readonly ColumnType<IBids>[]> = useCallback(() => {
    return {
      className: classNames(styles.headerRow, { [styles.selectionIsActive]: selectionIsActive })
    };
  }, [selectionIsActive]);

  const rowClassName = useCallback(
    (record: IBids): string =>
      classNames(styles.row, record.id === currentSidebar?.id ? 'ant-table-row-selected' : null, {
        [styles.selectionIsActive]: selectionIsActive
      }),
    [currentSidebar, selectionIsActive]
  );

  const onSort = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, (Key | boolean)[] | null>,
      sorter: SorterResult<IBids> | SorterResult<IBids>[]
    ) => {
      const prepareSortData = (sorter: SorterResult<IBids>): IBidSorting | undefined => {
        const { order } = sorter;
        const apiOrder = order === 'ascend' ? BidSortOrder.ASC : BidSortOrder.DESC;
        const column = sorter.column;
        return column && column.key && isBidSortField(column.key) && order
          ? { name: column.key as BidsColumn, order: apiOrder }
          : undefined;
      };

      if (Array.isArray(sorter)) {
        console.error('sorter is array. We do not support this');
      } else {
        const sortData = prepareSortData(sorter);
        updateSearchArgs({ ...searchVars, sortOnField: sortData });
        if (wsId) {
          trackWorkspaceTableSorting(
            {
              id: wsId
            },
            sorter.column?.title as string
          );
        }
      }
    },

    [searchVars, updateSearchArgs, wsId]
  );

  return (
    <div className={styles.container} ref={setContainer}>
      {(bidsData === undefined || loading || isLoading) && !error ? (
        <Skeleton loading active />
      ) : (
        <>
          {isEmptyTable || isEmptySearch ? (
            <EmptyFilter
              title={isEmptySearch ? t('BidSpaces.NoResults.title') : t('BidSpaces.NoBidsAdded.title')}
              desc={isEmptySearch ? t('BidSpaces.NoResults.desc') : t('BidSpaces.NoBidsAdded.desc')}
            />
          ) : (
            <div className={classNames(styles.content)} ref={ref}>
              <Table
                columns={columns}
                dataSource={bids}
                rowClassName={rowClassName}
                size={'large'}
                locale={{
                  emptyText: <></>,
                  triggerDesc: t('Common.clickToSortDescending'),
                  triggerAsc: t('Common.clickToSortAscending'),
                  cancelSort: t('Common.clickToCancelSorting')
                }}
                onHeaderRow={onHeaderRow}
                rowKey={'id'}
                rowSelection={rowSelectionConfig}
                components={components}
                onRow={onRow}
                onChange={onSort}
                footer={() => (
                  <LoadMoreButton
                    onLoadMore={onLoadMore}
                    isLoading={fetchMoreLoading}
                    hasMore={hasMore}
                    scrollContainer={container}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default BidsTable;
