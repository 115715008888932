import { useMemo } from 'react';
import dayjs from 'src/helpers/dayjs';
import { useTranslation } from 'react-i18next';

export function useDeadlineDate(deadline: number | null): { value: string; isExpired?: boolean } {
  const { t } = useTranslation();

  const deadlineValue = useMemo(() => {
    if (!deadline) {
      return { value: t('Common.notSet') };
    }
    const now = dayjs().utc().startOf('day');

    const currentYear = dayjs().year();

    const deadlineYear = dayjs(deadline).year();

    const startOfDeadlineDate = dayjs(deadline).utc().startOf('day');

    const diffBetweenDates = startOfDeadlineDate.diff(now, 'day');

    let value = t('Common.Format.utcDateFormat', { date: startOfDeadlineDate.toDate() });
    let isExpired = false;

    if (deadlineYear > currentYear) {
      const dateFormat = 'D MMM YYYY';
      value = startOfDeadlineDate.format(dateFormat);
    } else {
      const oneYearFromNow = dayjs().add(1, 'year').startOf('day');

      if (diffBetweenDates < 0) {
        value = t('Common.expired');
        isExpired = true;
      } else if (diffBetweenDates === 0) {
        value = t('Common.today');
      } else if (diffBetweenDates === 1) {
        value = t('Common.tomorrow');
      } else if (diffBetweenDates <= 7) {
        value = `${diffBetweenDates} ${t('Common.days')}`;
      } else {
        const dateFormat = startOfDeadlineDate.isBefore(oneYearFromNow) ? 'D MMM' : 'D MMM YYYY';
        value = startOfDeadlineDate.format(dateFormat);
      }
    }

    return { value, isExpired };
  }, [deadline, t]);
  return deadlineValue;
}

export default useDeadlineDate;
