import React, { ReactNode } from 'react';
import { ChartTooltipProps } from './hooks';
import { NumberValue, ScaleOrdinal } from 'd3-scale';
import { TickFormatter } from '@visx/axis';
import { LabelFormatter } from '@visx/legend/lib/types';
import { ParentSizeProvidedProps } from '@visx/responsive/lib/components/ParentSize';

export type XYChartProps = {
  width: number;
  height: number;
  xDomain: string[];
  yDomain: [number, number];
  colorScale: ScaleOrdinal<string, string>;
  margin?: { top: number; right: number; bottom: number; left: number };
  formatX?: TickFormatter<string>;
  formatY?: TickFormatter<NumberValue>;
  formatLegend?: LabelFormatter<string>;
  children?: ReactNode;
};

export type BaseChartProps<T> = {
  data: T[];
  getLegendKey: (d: T) => string;
  getXValue: (d: T) => string;
  getYValue: (d: T) => number;
  onHover?: (d: ChartTooltipProps<T>, i?: number) => void;
  offHover?: () => void;
  onClick?: (d: T) => void;
};

export type DateRangeProps<T> = Pick<BaseChartProps<T>, 'data' | 'onHover' | 'offHover'> & {
  getDateRanges: (dt: T) => [string, string];
};

export interface ChartContainerProps {
  title?: string;
  desc?: string;
  loading?: boolean;
  error?: boolean;
  className?: string;
  children: (args: ParentSizeProvidedProps) => React.ReactNode;
}

export const CHART_TEXT_COLOR = '#81818F';
export const CHART_DEFAULT_MARGIN = { top: 40, left: 50, right: 40, bottom: 100 };
export const CHART_COLOR_PALETTE = [
  '#85A5FF',
  '#95DE64',
  '#722ED1',
  '#5CDBD3',
  '#FF9C6E',
  '#B37FEB',
  '#FF85C0',
  '#006D75',
  '#AD6800',
  '#237804',
  '#FAAD14',
  '#A8071A'
];

export const BAR_OPACITY_BLUR = '30%';
export const BAR_RADIUS = 4;
export const BAR_MIN_HEIGHT = 16;
export const SPACE_BETWEEN_BARS = 2;

export const LINE_HEIGHT = 3;

export const CIRCLE_RADIUS = 6;
export const CIRCLE_BORDER = 2;
export const SPACE_BETWEEN_CIRCLES = 3;

export const GRID_COLOR = '#DBDBDD';

export const DATE_RANGE_LINE_COLOR = '#FA941C';
export const DATE_RANGE_LINE_CIRCLE_COLOR = '#5357EA';

export const DATE_RANGE_BAR_COLOR = ['#FA8C16', '#FFD591'];
export const DATE_RANGE_BAR_HEIGHT = 20;
