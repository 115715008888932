import { FieldPolicy } from '@apollo/client';
import { IBidsCached } from '../Bids/types';

export function getAllBidsPolicy(): FieldPolicy {
  return {
    keyArgs: [
      '@connection',
      'input',
      [
        'search',
        'stageIds',
        'rejected',
        'wsId',
        'sort',
        'assignedToIds',
        'bidItemTypes',
        'statusCategories',
        'workspaceIds',
        'textSearch'
      ]
    ],
    read(existing: IBidsCached) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing;
      }
    },
    merge(existing: IBidsCached, incoming: IBidsCached, { args, storeFieldName }) {
      // paginated view, fill with null
      if (storeFieldName.includes('pages')) {
        let merged = existing?.bids ? [...existing.bids] : [];
        if (incoming) {
          if (args) {
            const offset = args?.input.pagination?.offset;
            for (let i = 0; i < incoming.bids.length; ++i) {
              merged[offset + i] = incoming.bids[i];
            }
          } else {
            merged = [...merged, ...incoming.bids];
          }
          for (let i = 0; i < incoming.total; ++i) {
            merged[i] = merged[i] ?? null;
          }
        }

        return {
          ...incoming,
          bids: merged
        };
      }

      if (!existing) {
        return incoming;
      }
      if (incoming && !!incoming.bids && incoming.bids.length === 0) {
        return { ...existing, total: incoming.total, totalBids: incoming.totalBids };
      }
      if (incoming && incoming.bids) {
        const merged = existing && existing.bids ? [...existing.bids] : [];
        const offset = args?.input.pagination?.offset;
        if (offset) {
          for (let i = 0; i < incoming.bids.length; ++i) {
            merged[offset + i] = incoming.bids[i];
          }
        }
        return { ...existing, bids: merged, total: incoming.total, totalBids: incoming.totalBids };
      }
      if (incoming && !incoming.bids && incoming.totalBids) {
        return { ...existing, totalBids: incoming.totalBids };
      }
    }
  };
}
