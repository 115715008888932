import React, { FC, useMemo, useCallback } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { WorkspacePicker } from 'src/shared';
import useSelectedBids from 'src/reactiveVars/SelectedBidsVar';
import { IBidIdentifier } from 'src/models/bids/Bids/types';

export const BidsActionsMultiple: FC = () => {
  const [selectedBids, setSelectedBids] = useSelectedBids();
  const clearSelectedBids = useCallback(() => {
    setSelectedBids([]);
  }, [setSelectedBids]);
  const bidIds: IBidIdentifier[] = useMemo(
    () =>
      selectedBids.map(bid => {
        return { id: bid.id, wsId: bid.workspace.id };
      }),
    [selectedBids]
  );

  return (
    <ul className={styles.actionsMultipleTenders}>
      <li className={classNames(styles.actionMultipleTenders, styles.addToWsAction)}>
        <WorkspacePicker
          bidIds={bidIds}
          eventSource={'WorkspaceMultipleBids'}
          onChange={clearSelectedBids}
          isSelected={!!selectedBids.length}
        />
      </li>
    </ul>
  );
};
export default BidsActionsMultiple;
