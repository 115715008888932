import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { faFaceDisappointed, faMedal, faPaperPlaneTop, faPen, faSeedling } from '@fortawesome/pro-light-svg-icons';
import { IIconMapper } from 'src/types/icon';

export const WORKSPACE_STATUS_CATEGORY_ICON_MAPPER: IIconMapper = {
  [WorkspaceStatusCategory.Interesting]: { defaultIcon: faSeedling, color: '#D48806' },
  [WorkspaceStatusCategory.InProgress]: { defaultIcon: faPen, color: '#3B3BC4' },
  [WorkspaceStatusCategory.Submitted]: { defaultIcon: faPaperPlaneTop, color: '#531DAB' },
  [WorkspaceStatusCategory.Won]: { defaultIcon: faMedal, color: '#389E0D' },
  [WorkspaceStatusCategory.Lost]: { defaultIcon: faFaceDisappointed, color: '#CF1322' }
};
