import { safeCallSegment } from '../helpers';

export function trackClickOnNotificationsList(): void {
  safeCallSegment(() => {
    analytics.track('Click on notifications list', {});
  });
}

export function trackClickOnShowAllNotifications(): void {
  safeCallSegment(() => {
    analytics.track('Click on show all notifications', {});
  });
}

export function trackOpenNotificationFromEmail(
  procurement: {
    id: string;
    name?: string;
  },
  comment: { id: string }
): void {
  safeCallSegment(() => {
    analytics.track('Open comment notification from email', {
      procurement,
      comment
    });
  });
}

export function trackOpenInAppNotification(
  procurement: {
    id: string;
    name?: string;
  },
  comment: { id: string }
): void {
  safeCallSegment(() => {
    analytics.track('Open comment notification in app', {
      procurement,
      comment
    });
  });
}

export function trackNotificationsDropdown(onOff: boolean, eventSource: string, id: string): void {
  safeCallSegment(() => {
    analytics.track('Dropdown notifications', {
      onOff,
      eventSource,
      id
    });
  });
}
