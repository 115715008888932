import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown } from 'src/common';
import styles from './index.module.scss';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { useBidspacesQpVars, useBidsFilterVars } from 'src/models/bids/Bids/hooks';
import { trackABFilter } from 'src/segment/events/allBidspaces';

const BidspacesFilterCategory: FC = () => {
  const { t } = useTranslation();
  const { updateVars } = useBidsFilterVars();
  const vars = useBidspacesQpVars();
  const currentFilters = useMemo(() => vars ?? {}, [vars]);
  const selectedCategories = useMemo(
    () => (currentFilters.category ? currentFilters.category : undefined),
    [currentFilters]
  );
  const categories = Object.keys(WorkspaceStatusCategory).map(key => {
    return {
      label: t(`BidSpaces.WorkspaceStatusCategory.${key}`),
      value: key
    };
  });

  const onChange = useCallback(
    (category: string, checked?: boolean) => {
      const previousValue = selectedCategories || [];
      const currentValue = checked ? [...previousValue, category] : previousValue.filter(item => item !== category);

      updateVars?.({ category: currentValue });

      if (checked) trackABFilter('Status');
    },
    [selectedCategories, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedCategories?.length })}>
      <PlainDropDown
        values={categories}
        selectedValue={selectedCategories}
        label={t('BidSpaces.Filters.category')}
        onSelect={onChange}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default BidspacesFilterCategory;
